// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.green-text{
    color: green;
}
.gray-text{
    color: gray;
    font-size: 10px;
    line-height: .2;
    text-transform: uppercase;
}
.profile-image{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}
.graph-container{
    min-height: 170px;
}
.device-report{
    font-size: 12px;
    line-height: .5;
    text-transform: capitalize;
}
.hr-line{
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Super-Admin/Dashboard/components/LivePlayers/livePlayer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,WAAW;IACX,eAAe;IACf,eAAe;IACf,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".green-text{\n    color: green;\n}\n.gray-text{\n    color: gray;\n    font-size: 10px;\n    line-height: .2;\n    text-transform: uppercase;\n}\n.profile-image{\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    float: left;\n    margin-right: 10px;\n}\n.graph-container{\n    min-height: 170px;\n}\n.device-report{\n    font-size: 12px;\n    line-height: .5;\n    text-transform: capitalize;\n}\n.hr-line{\n    width: 98%;\n    margin-left: auto;\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
