// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-container{
display: flex;
flex-direction: column;
}

.tree-name{
text-align: left;
position: relative;

}`, "",{"version":3,"sources":["webpack://./src/components/Hierarchy/Hierarchy.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,sBAAsB;AACtB;;AAEA;AACA,gBAAgB;AAChB,kBAAkB;;AAElB","sourcesContent":[".tree-container{\ndisplay: flex;\nflex-direction: column;\n}\n\n.tree-name{\ntext-align: left;\nposition: relative;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
