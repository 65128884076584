// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tenant-select {
  height: 30px;
  padding: 2px 25px 2px 20px;
  position: relative;
  bottom: 7px;
}

.tenant.main-nav {
  height: 40px;
}

.tenant img {
  height: 50px;
  width: 50px;
  position: relative;
  bottom: 7px;
}

#notification-dropdown {
  padding: 0;
}

.notification-icon {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
}
.notification-icon .dot {
  position: absolute;
  top: 7px;
  right: 7px;
  background-color: red;
  width: 12px;
  height: 12px;
  border-radius: 50px;
}
.notification-icon .svg-inline--fa {
  height: 1.4rem;
}

.notification-dropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px !important;
  width: 100% !important;
}
.notification-dropdown .dropdown-item {
  background: #eeeeee;
  border-radius: 8px;
}
.notification-dropdown .notification-content {
  width: 100%;
  border-radius: 6px;
  white-space: wrap;
  color: black;
  font-weight: 400;
}
.notification-dropdown.dropdown-menu {
  min-width: 450px;
}
.notification-dropdown.dropdown-menu[style] {
  right: 0 !important;
  left: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,0BAAA;EACA,kBAAA;EACA,WAAA;AACF;;AACA;EACA,YAAA;AAEA;;AAAA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AAGJ;;AADA;EACE,UAAA;AAIF;;AAFA;EAUE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,0CAAA;EACA,kBAAA;AAJF;AAZE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAcJ;AAJE;EACE,cAAA;AAMJ;;AAHA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;EACA,sBAAA;AAMF;AALE;EACE,mBAAA;EACA,kBAAA;AAOJ;AALE;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;AAOJ;AALC;EACC,gBAAA;AAOF;AANE;EACE,mBAAA;EACA,qBAAA;AAQJ","sourcesContent":[".tenant-select{\n  height: 30px;\n  padding: 2px 25px 2px 20px;\n  position: relative;\n  bottom: 7px;\n}\n.tenant.main-nav{\nheight: 40px;\n}\n.tenant img{\n    height: 50px;\n    width: 50px;\n    position: relative;\n    bottom:7px\n}\n#notification-dropdown{\n  padding: 0;\n}\n.notification-icon{\n  .dot{\n    position: absolute;\n    top: 7px;\n    right: 7px;\n    background-color: red;\n    width: 12px;\n    height: 12px;\n    border-radius: 50px;\n  }\n  width: 40px;\n  height: 40px;\n  border-radius: 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgb(255 255 255 / 10%);\n  position: relative;\n  .svg-inline--fa{\n    height: 1.4rem;\n  }\n}\n.notification-dropdown{\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: 400px !important;\n  width: 100% !important;\n  .dropdown-item{\n    background: #eeeeee;\n    border-radius: 8px;\n  }\n  .notification-content{\n    width: 100%;\n    border-radius: 6px;\n    white-space: wrap;\n    color: black;\n    font-weight: 400;\n  }\n &.dropdown-menu {\n  min-width: 450px;\n  &[style]{\n    right: 0 !important;\n    left: auto !important;\n  }\n }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
