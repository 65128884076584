// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  max-width: 400px;
  margin: auto;
  margin-top: 24px;
}

.red-color {
  color: red;
}

.width-spacing {
  width: 130px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/VoucherTransactions/components/VoucherTransactionsList/voucherTransaction.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;AACF","sourcesContent":[".card-container {\n  max-width: 400px;\n  margin: auto;\n  margin-top: 24px;\n}\n\n.red-color {\n  color: red;\n}\n\n.width-spacing {\n  width: 130px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
