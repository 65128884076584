// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-body-container {
  max-height: 500px;
  overflow: auto;
}
.table-body-container table {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/common/SportsbookBonus/SportsBookBonus.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,cAAA;AAAF;AAEE;EACE,WAAA;AAAJ","sourcesContent":["\r\n.table-body-container {\r\n  max-height: 500px; // Set the max height for the table body\r\n  overflow: auto;  // Enable scrolling for the body\r\n\r\n  table {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
