// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-async-select {
  min-width: 240px;
  max-width: 240px;
}`, "",{"version":3,"sources":["webpack://./src/components/AsyncSelect/CustomAsyncSelect.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".custom-async-select{\r\n  min-width: 240px;\r\n  max-width: 240px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
