// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchWrapper {
  padding: 0.2rem 0.3rem !important;
  font-size: 0.875rem !important;
  border-radius: 10px !important;
}
.searchWrapper .searchBox {
  margin-top: 0px !important;
}
.searchWrapper input::-webkit-input-placeholder {
  color: #66799e !important;
}

.optionListContainer li {
  padding: 3px 10px !important;
  font-size: 0.875rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ActionTypeSelect/MultiSelect.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,8BAAA;EACA,8BAAA;AACF;AAAE;EACE,0BAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ;;AAGG;EACC,4BAAA;EACA,8BAAA;AAAJ","sourcesContent":[".searchWrapper {\n  padding: 0.2rem 0.3rem !important;\n  font-size: 0.875rem !important;\n  border-radius: 10px !important;\n  .searchBox {\n    margin-top: 0px !important;\n  }\n  input::-webkit-input-placeholder {\n    color: #66799e !important;\n  }\n}\n\n.optionListContainer {\n   li {\n    padding: 3px 10px !important;\n    font-size: 0.875rem !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
