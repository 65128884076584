// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h4-overview {
    margin: 10px 10px 0 10px;
}

.div-overview {
    margin: 5px 15px 15px 15px
}

.h4-hr {
    margin: 10px 0 15px 0;
}

.col-padding {
    padding-right: 0%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Tenant/UserDetails/components/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI;AACJ;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".h4-overview {\n    margin: 10px 10px 0 10px;\n}\n\n.div-overview {\n    margin: 5px 15px 15px 15px\n}\n\n.h4-hr {\n    margin: 10px 0 15px 0;\n}\n\n.col-padding {\n    padding-right: 0%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
