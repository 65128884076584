import { Button, Form as BForm, Modal } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import useCreateSeason from './useCreateSeason'
import { creatSeasonSchema } from '../schema'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import InputDatePicker from '../../../../components/FormElements/InputDatePicker/InputDatePicker'
import { getCurrentLang, returnLangName } from '../../../../utils/storageUtils'
const CreateAndEditSeason = ({
  handleClose,
  setRefetch,
  show,
  type,
  seasonData
}) => {
  const {
    languages,
    clientsFilterData,
    t,
    getTemplateList,
    templateList,
    handleCreateSeason,
    createLoading,
    handleUpdateSeason
  } = useCreateSeason({ seasonData })
  return (
    <>
      <Formik
        enableReinitialize={seasonData}
        initialValues={
          {
            seasonName: seasonData ? returnLangName(seasonData.seasonName) : '',
            lang: getCurrentLang(),
            templateId: seasonData ? seasonData.templateId : '',
            tenantId: seasonData ? seasonData.tenantId : '',
            startDate: seasonData ? moment(seasonData.startDate).toDate() : moment().toDate(),
            endDate: seasonData ? moment(seasonData.endDate).toDate() : moment().add(1, 'day').toDate(),
            file: seasonData ? seasonData.seasonUrl : null
          }
        }
        validationSchema={creatSeasonSchema(t)}
        onSubmit={(formValues, formActions) => {
          if (seasonData) {
            handleUpdateSeason(formValues, handleClose, setRefetch, formActions)
          } else {
            handleCreateSeason(formValues, handleClose, setRefetch, formActions)
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          handleReset,
          setFieldError
        }) => {
          return (
            <Form>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                centered
              >
                <Modal.Header closeButton>
                  <h4 className='d-flex align-items-center'>
                    {t(type)}
                  </h4>
                </Modal.Header>
                <Modal.Body>
                  <div className='d-flex flex-column gap-2'>
                    <div>
                      <BForm.Label>{t('owner')}
                        <span className='text-danger'> *</span>
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          type='select'
                          name='tenantId'
                          disabled={seasonData}
                          value={values?.tenantId}
                          onChange={(e) => {
                            handleChange(e)
                            getTemplateList(e.target.value)
                          }}
                          onBlur={handleBlur}
                        >
                          <option key='' value=''>{seasonData ? '' : t('selectOwner')}</option>
                          {clientsFilterData?.rows?.map(
                            ({ adminUserId, firstName, lastName, Tenants }) => (
                              <option key={adminUserId} value={Number(Tenants[0]?.tenantId)}>
                                {`${firstName} ${lastName}`}
                              </option>
                            )
                          )}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='tenantId'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>{t('template')}
                        <span className='text-danger'> *</span>
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          type='select'
                          name='templateId'
                          disabled={seasonData}
                          value={values?.templateId}
                          onChange={(e) => {
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                        >
                          <option key='' value=''>{t('selectTemplate')}</option>
                          {templateList?.templates?.rows?.map(
                            (template) => (
                              <option key={template?.templateId} value={template?.templateId}>
                                {returnLangName(template?.templateName)}
                              </option>
                            )
                          )}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='templateId'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>{t('selectLanguage')} <span className='text-danger'> *</span></BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          name='lang'
                          value={values.lang}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {languages && languages?.filter(item => item.isActive).map(({
                            languageName,
                            code
                          }, index) => {
                            return (
                              <option
                                key={index}
                                value={code.toLowerCase()}
                              >
                                {t(String(languageName).toLowerCase())}
                              </option>
                            )
                          })}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='lang'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>
                        {t('nameOfSeason')}<span className='text-danger'> *</span>
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Control
                          type='text'
                          name='seasonName'
                          placeholder={t('enterName')}
                          value={values.seasonName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='seasonName'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div className='d-flex flex-column gap-1'>
                      <InputDatePicker
                        isLabelSm
                        isRequired
                        isForm
                        label={t('startDate')}
                        value={values.startDate}
                        onChange={(val) => {
                          setFieldValue('startDate', val)
                        }}
                      />
                      <ErrorMessage
                        component='div'
                        name='startDate'
                        className='text-danger'
                      />
                    </div>
                    <div className='d-flex flex-column gap-1'>
                      <InputDatePicker
                        isLabelSm
                        isRequired
                        isForm
                        minDate={values.startDate}
                        label={t('endDate')}
                        value={values.endDate}
                        onChange={(val) => {
                          setFieldValue('endDate', val)
                        }}
                      />
                      <ErrorMessage
                        component='div'
                        name='endDate'
                        className='text-danger'
                      />
                    </div>
                    <div>
                      <BForm.Label>{t('banner')} <span className='text-danger'> *</span></BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Text>
                          <input
                            id='file'
                            name='file'
                            type='file'
                            onChange={(event) => {
                              setFieldValue(
                                'file',
                                event.currentTarget.files[0]
                              )
                            }}
                          />

                        </BForm.Text>
                        {values?.file &&
                          (
                            <img
                              alt='file'
                              style={{ width: 150, maxHeight: 150, objectFit: 'contain' }}
                              src={
                                typeof values?.file !== 'string'
                                  ? URL.createObjectURL(values?.file)
                                  : values?.file
                              }
                            />
                          )}
                        <ErrorMessage
                          component='div'
                          name='file'
                          className='text-danger'
                        />
                      </div>
                    </div>
                  </div>

                </Modal.Body>

                <div className='mt-4 '>
                  <Modal.Footer className='d-flex justify-content-between align-items-center'>
                    <Button
                      variant='outline-warning'
                      onClick={() => {
                        handleClose()
                        handleReset()
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      type='submit'
                      variant='outline-success'
                      onClick={handleSubmit}
                      className='ml-2'
                    >
                      {t('submit')}
                      {createLoading && (
                        <Spinner className='ms-2' size='sm' animation='border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </Spinner>
                      )}
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default CreateAndEditSeason
