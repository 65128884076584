import { createSlice } from '@reduxjs/toolkit'

const adminsSlice = createSlice({
  name: 'admins',
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null,
    adminDetails: null,
    adminRoleId: null,
    adminPermissions: null,
    adminChildren: null,
    adminData: null,
    superAdminUser: null,
    bannerTypes: {},
    bannerTypeLoading: false,
    casinoReportingData: [],
    UserReportingData: [],
    userHistory: [],
    updatePasswordMessage: '',
    superadminUpdatePasswordMessage: '',
    securityReport: {},
    bonusList: {
      rows: [],
      count: 0
    },
    depositAddEditLoading: false,
    addonReportingData: [],
    addonReportingLoading: false,
    casinoLogData: [],
    errorLogData: [],
    userMigrationReport: [],
    rollTransactions: [],
    promotionCashAddEditLoading: false,
    sportsbookProviders: {},
    sportsProviderUpdateLoading: false
  },
  reducers: {
    getAddonReportingDataStart: (state) => ({
      ...state,
      addonReportingLoading: true
    }),
    getAddonReportingDataSuccess: (state, { payload }) => ({
      ...state,
      addonReportingLoading: false,
      addonReportingData: payload
    }),
    getAddonReportingDataFailure: (state, { payload }) => ({
      ...state,
      addonReportingLoading: false,
      error: payload
    }),
    updateBonusList: (state, { payload }) => ({
      ...state,
      bonusList: {
        rows: [payload, ...state?.bonusList.rows],
        count: state?.bonusList.count + 1
      }
    }),
    updateUserPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    updateUserPasswordSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      updatePasswordMessage: payload
    }),
    updateUserPasswordFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    superadminUpdateUserPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    superadminUpdateUserPasswordSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      superadminUpdatePasswordMessage: payload
    }),
    superadminUpdateUserPasswordFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getAllUserHistoryStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllUserHistorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userHistory: payload
    }),
    getAllUserHistoryFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getSecurityReportStart: (state) => ({
      ...state,
      loading: true
    }),
    getSecurityReportSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      securityReport: payload
    }),
    getSecurityReportFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getUserReportingDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserReportingDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      UserReportingData: payload
    }),
    getUserReportingDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getCasinoReportingDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getCasinoReportingDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoReportingData: payload
    }),
    getCasinoReportingDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getBannerTypesStart: (state) => ({
      ...state,
      bannerTypeLoading: true
    }),
    getBannerTypesSuccess: (state, { payload }) => ({
      ...state,
      bannerTypeLoading: false,
      bannerTypes: payload
    }),
    getBannerTypesFailure: (state, { payload }) => ({
      ...state,
      bannerTypeLoading: false
    }),
    getAllAdminsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllAdminsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      data: payload
    }),
    getAllAdminsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAdminDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminDetails: payload
    }),
    getAdminDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAffiliateAllDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAffiliateAllDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminDetails: payload?.data,
      adminPermissions: payload?.isAgent ? payload?.data?.agentPermission?.permission : payload?.data?.affiliatePermission?.permission
    }),
    getAffiliateAllDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getSuperAdminDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminDetails: payload,
      adminPermissions: payload?.userPermission?.permission,
      adminRoleId: payload?.superRoleId
    }),
    getSuperAdminDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateTenantAdminStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantAdminStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllBonusComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      bonusList: payload

    }),
    getAllBonusFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAdminChildrenStart: (state) => ({
      ...state,
      loading: true
    }),
    getAdminChildrenSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      adminChildren: payload
    }),
    getAdminChildrenFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    resetAdminChildren: (state) => ({
      ...state,
      loading: false,
      adminChildren: null
    }),
    getAdminDataStart: (state) => ({
      ...state,
      loading: true,
      adminData: null
    }),
    getAdminDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      adminData: payload
    }),
    getAdminDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    addUserInternalTagtStart: (state) => ({
      ...state,
      loading: true
    }),
    addUserInternalTagtSuccess: (state) => ({
      ...state,
      loading: false
    }),
    addUserInternalTagtFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateReorderBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateReorderBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getLoggedSADetailsSuccess: (state, { payload }) => ({
      ...state,
      superAdminUser: payload
    }),
    updateEntityStart: (state) => ({
      ...state,
      entityUpdatingLoader: true
    }),
    updateEntitySuccess: (state, { payload }) => ({
      ...state,
      entityUpdatingLoader: false,
      entityUpdatingMessage: payload
    }),
    updateEntityFailure: (state) => ({
      ...state,
      entityUpdatingLoader: false
    }),
    depositAddonAddEditStart: (state) => ({
      ...state,
      depositAddEditLoading: true
    }),
    depositAddonAddEditSuccess: (state, { payload }) => ({
      ...state,
      depositAddEditLoading: false
    }),
    depositAddonAddEditFailure: (state) => ({
      ...state,
      depositAddEditLoading: false
    }),
    getCasinoLogsDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getCasinoLogsDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoLogData: payload
    }),
    getCasinoLogsDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getErrorLogsDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getErrorLogsDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      errorLogData: payload
    }),
    getErrorLogsDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getAllUserMigrationDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllUserMigrationDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userMigrationReport: payload
    }),
    getAllUserMigrationDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getAllRollTransactionsDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllRollTransactionsDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      rollTransactions: payload
    }),
    getAllRollTransactionsDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    promotionCashAddEditStart: (state) => ({
      ...state,
      promotionCashAddEditLoading: true
    }),
    promotionCashAddEditSuccess: (state, { payload }) => ({
      ...state,
      promotionCashAddEditLoading: false
    }),
    promotionCashAddEditFailure: (state) => ({
      ...state,
      promotionCashAddEditLoading: false
    }),
    getAllSportsbookProvidersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllSportsbookProvidersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sportsbookProviders: payload
    }),
    getAllSportsbookProvidersFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    sportsProviderUpdateStart: (state) => ({
      ...state,
      sportsProviderUpdateLoading: true
    }),
    sportsProviderUpdateSuccess: (state, { payload }) => ({
      ...state,
      sportsProviderUpdateLoading: false
    }),
    sportsProviderUpdateFailure: (state) => ({
      ...state,
      sportsProviderUpdateLoading: false
    })
  }
})

export default adminsSlice.reducer

export const {
  depositAddonAddEditFailure,
  depositAddonAddEditSuccess,
  depositAddonAddEditStart,
  promotionCashAddEditFailure,
  promotionCashAddEditSuccess,
  promotionCashAddEditStart,
  sportsProviderUpdateFailure,
  sportsProviderUpdateSuccess,
  sportsProviderUpdateStart,
  getAllSportsbookProvidersFailure,
  getAllSportsbookProvidersSuccess,
  getAllSportsbookProvidersStart,
  getAllAdminsStart,
  getAllAdminsSuccess,
  getAllAdminsFailure,
  getAdminChildrenStart,
  getAdminChildrenSuccess,
  getAdminChildrenFailure,
  getAdminDetailsStart,
  getAdminDetailsSuccess,
  getAdminDetailsFailure,
  getSuperAdminDetailsStart,
  getSuperAdminDetailsSuccess,
  getSuperAdminDetailsFailure,
  updateTenantAdminStatusStart,
  updateTenantAdminStatusComplete,
  getAllBonusStart,
  getAllBonusComplete,
  getAllBonusFailure,
  resetAdminChildren,
  getAdminDataStart,
  getAdminDataSuccess,
  getAdminDataFailure,
  addUserInternalTagtStart,
  addUserInternalTagtSuccess,
  addUserInternalTagtFailure,
  updateReorderBonusStart,
  updateReorderBonusComplete,
  getLoggedSADetailsSuccess,
  getBannerTypesStart,
  getBannerTypesSuccess,
  getBannerTypesFailure,
  getCasinoReportingDataStart,
  getCasinoReportingDataSuccess,
  getCasinoReportingDataFailure,
  getUserReportingDataStart,
  getUserReportingDataSuccess,
  getUserReportingDataFailure,
  getAllUserHistoryStart,
  getAllUserHistorySuccess,
  getAllUserHistoryFailure,
  updateUserPasswordStart,
  updateUserPasswordSuccess,
  updateUserPasswordFailure,
  superadminUpdateUserPasswordStart,
  superadminUpdateUserPasswordSuccess,
  superadminUpdateUserPasswordFailure,
  getAffiliateAllDetailsStart,
  getAffiliateAllDetailsSuccess,
  getAffiliateAllDetailsFailure,
  getSecurityReportStart,
  getSecurityReportSuccess,
  getSecurityReportFailure,
  updateBonusList,
  updateEntityStart,
  updateEntitySuccess,
  updateEntityFailure,
  getAddonReportingDataStart,
  getAddonReportingDataSuccess,
  getAddonReportingDataFailure,
  getCasinoLogsDataStart,
  getCasinoLogsDataSuccess,
  getCasinoLogsDataFailure,
  getErrorLogsDataStart,
  getErrorLogsDataSuccess,
  getErrorLogsDataFailure,
  getAllUserMigrationDataStart,
  getAllUserMigrationDataSuccess,
  getAllUserMigrationDataFailure,
  getAllRollTransactionsDataStart,
  getAllRollTransactionsDataSuccess,
  getAllRollTransactionsDataFailure
} = adminsSlice.actions
