// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input-field {
  width: 100%;
  min-height: 30px;
  color: #66799e;
  padding-left: 15px;
  border: 0.0625rem solid #d1d7e0;
  font-size: 10px;
}

.parent-center-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.7294117647);
  top: 0;
  left: 0;
  z-index: 99999;
}

.center-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.custom-container:has(div) {
  margin-top: 63%;
}
.custom-container:has(div) .player {
  margin-top: 345px !important;
}

@media only screen and (max-width: 580px) {
  .DateRangePickerWithoutInputWrapper {
    margin-bottom: 30px;
  }

  .custom-container {
    position: absolute;
    top: 85px;
    left: 30px;
  }

  .noOption .custom-container {
    top: 55px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DateRangePicker/DateRangePicker.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,+BAAA;EACA,eAAA;AACF;;AACA;EACI,YAAA;EACA,aAAA;EACA,eAAA;EACA,uCAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;AAEJ;;AAAA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,4BAAA;EACA,gCAAA;AAGF;;AAAA;EAEE,eAAA;AAEF;AAAE;EACE,4BAAA;AAEJ;;AAGA;EACE;IACE,mBAAA;EAAF;;EAEA;IACE,kBAAA;IACA,SAAA;IACA,UAAA;EACF;;EACA;IACE,SAAA;EAEF;AACF","sourcesContent":[".custom-input-field {\n  width: 100%;\n  min-height: 30px;\n  color: #66799e;\n  padding-left: 15px;\n  border: 0.0625rem solid #d1d7e0;\n  font-size: 10px;\n}\n.parent-center-screen{\n    width:100vw;\n    height:100vh;\n    position: fixed;\n    background: #000000ba;\n    top: 0;\n    left: 0;\n    z-index: 99999;\n}\n.center-screen{\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  /* bring your own prefixes */\n  transform: translate(-50%, -50%);\n}\n\n.custom-container:has(div){\n  // width: 69%;\n  margin-top: 63%;\n\n  .player{\n    margin-top: 345px !important;\n  }\n}\n\n\n@media only screen and (max-width: 580px) {\n  .DateRangePickerWithoutInputWrapper{\n    margin-bottom: 30px;\n  }\n  .custom-container{\n    position: absolute;\n    top: 85px;\n    left: 30px;\n  }\n  .noOption .custom-container{\n    top: 55px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
