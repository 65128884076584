import React from 'react'
import useBonusTransactions from '../hooks/useBonusTransactions'
import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import BonusTransactionsList from '../../../../components/BonusTransactionsList'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { bonusType as BONUSTYPES, bonusTypeV2 as BONUSTYPESV2 } from '../../Bonus/components/CreateBonus/constants'

import ClientFilter from '../../../../components/ClientFilter'
import AffiliateAgentFilter from '../../../../common/AffiliateAgentFilter'
import InputDatePicker from '../../../../components/FormElements/InputDatePicker/InputDatePicker'

const BonusTransactions = ({ isReporting, isSportsBook, userData = {} }) => {
  const {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    state,
    setState,
    handleCsvDownload,
    initialDate,
    setBonusType,
    bonusType,
    bonusTransactions,
    bonusTransactionsLoading,
    selectedClient,
    setSelectedClient,
    inputRef,
    handleDebouncSearch,
    setSearch,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    isBonusTransactionReportV2,
    setIsBonusTransactionReportV2
  } = useBonusTransactions({ isReporting, isSportsBook, userData })

  return (
    <div className='p-2'>
      {isReporting && (
        <Row>
          <Col xs='auto'>
            <Col xs='auto' className='mb-3'>
              <h3>{isSportsBook ? t('sportsBonusTransactions') : t('casinoBonusTransactions')}</h3>
            </Col>
          </Col>
        </Row>
      )}

      <Row className={`search-section pb-2 gap-2  ${isReporting ? 'mt-1' : 'mt-5'}`}>

        {isReporting && (
          <>
            <Col xs='auto' className='d-flex '>
              <ClientFilter
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
                setPage={setPage}
              />
              <AffiliateAgentFilter
                selectedClient={selectedClient}
                agent={agent}
                setAgent={setAgent}
                affiliate={affiliate}
                setAffiliate={setAffiliate}
              />
            </Col>
          </>
        )}
        <Col xs='auto' className='d-flex '>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('bonusType')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setBonusType(e.target.value)
            }}
            value={bonusType}
          >
            <option value=''>{t('selectBonus')}</option>
            {((isBonusTransactionReportV2 || userData?.isV2User) ? BONUSTYPESV2 : BONUSTYPES)?.map(
              (item) => (
                <option key={item.value} value={item.value}>
                  {t(item.label)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex '>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}
          </Form.Label>

          <Form.Control
            type='search'
            placeholder={t('searchUsername')}
            size='sm'
            style={{
              width: 'auto',
              marginRight: '10px',
              fontSize: '13px'
            }}
            ref={inputRef}
            onChange={handleDebouncSearch}
          />
        </Col>
        <Col xs='auto' className='d-flex'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex '>
          <Trigger message={t('downloadAsCSV')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={bonusTransactions?.count === 0}
              onClick={() => {
                handleCsvDownload()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>
        {!isSportsBook && isReporting &&
          <Col xs='auto'>
            <div className='d-flex justify-content-start align-items-center w-100'>
              <Form.Label
                className='flex-shrink-0'
                style={{
                  width: 'auto',
                  marginRight: '15px',
                  fontSize: '13px',
                  marginTop: '5px'
                }}
              >
                {t('bonusReportV2')}
              </Form.Label>

              <Form.Switch
                onChange={(e) => {
                  setIsBonusTransactionReportV2(e.target.checked)
                }}
                checked={isBonusTransactionReportV2}
                size='sm'
                style={{
                  maxWidth: '180px',
                  marginRight: '10px',
                  height: 'auto'
                }}
              />
            </div>
          </Col>}
        <Col xs='auto' className='d-flex '>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setLimit(15)
                setPage(1)
                setState(initialDate)
                setBonusType('')
                setSelectedClient('')
                setSearch('')
                inputRef.current.value = ''
                setAgent('')
                setAffiliate('')
                setIsBonusTransactionReportV2(false)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>
      <BonusTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={bonusTransactions}
        isTenant={false}
        userDetail
        loading={bonusTransactionsLoading}
        isBonusTransactionReportV2={isBonusTransactionReportV2}
        t={t}
      />
    </div>
  )
}

export default BonusTransactions
