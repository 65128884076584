import { takeLatest, put, select } from 'redux-saga/effects'
import {
  addInternalTag,
  getAdmin,
  getAdminChildren,
  getAllAdmins,
  getAllBonus,
  getSuperAdminDetails,
  getTenantOwnerDetails,
  superAdminViewToggleStatus,
  tenantViewToggleStatus,
  reorderBonus,
  getBannerTypes,
  getCasinoReportingData,
  getUserReportingData,
  getAllUserHistory,
  updateUserPasswordRequest,
  superadminUpdatePassword,
  getAffiliateAllDetails,
  getSecurityReport,
  updateEntity,
  depositAddonTypeAdd,
  depositAddonTypeEdit,
  getAddonReportingData,
  getCasinoLogsData,
  getErrorLogsData,
  getUserMigratedData,
  getAllRollTransactions,
  promotionCashAddUpdate,
  updateSportsProvider,
  getAllSportsProvider
} from '../../utils/apiCalls'

import {
  updateEntityStart,
  updateEntitySuccess,
  updateEntityFailure,
  getAffiliateAllDetailsStart,
  getAffiliateAllDetailsSuccess,
  getAffiliateAllDetailsFailure,
  getBannerTypesStart,
  getBannerTypesSuccess,
  getBannerTypesFailure,
  getAllAdminsStart,
  getAllAdminsSuccess,
  getAllAdminsFailure,
  getAdminDetailsStart,
  getAdminDetailsSuccess,
  getAdminDetailsFailure,
  getSuperAdminDetailsStart,
  getSuperAdminDetailsSuccess,
  getSuperAdminDetailsFailure,
  updateTenantAdminStatusStart,
  updateTenantAdminStatusComplete,
  getAllBonusStart,
  getAllBonusComplete,
  getAllBonusFailure,
  getAdminChildrenStart,
  getAdminChildrenSuccess,
  getAdminChildrenFailure,
  getAdminDataStart,
  getAdminDataSuccess,
  getAdminDataFailure,
  addUserInternalTagtStart,
  addUserInternalTagtSuccess,
  addUserInternalTagtFailure,
  updateReorderBonusStart,
  updateReorderBonusComplete,
  getLoggedSADetailsSuccess,
  getCasinoReportingDataStart,
  getCasinoReportingDataSuccess,
  getCasinoReportingDataFailure,
  getUserReportingDataStart,
  getUserReportingDataSuccess,
  getUserReportingDataFailure,
  getAllUserHistoryStart,
  getAllUserHistorySuccess,
  getAllUserHistoryFailure,
  updateUserPasswordStart,
  updateUserPasswordSuccess,
  updateUserPasswordFailure,
  superadminUpdateUserPasswordStart,
  superadminUpdateUserPasswordSuccess,
  superadminUpdateUserPasswordFailure,
  getSecurityReportSuccess,
  getSecurityReportFailure,
  getSecurityReportStart,
  depositAddonAddEditFailure,
  depositAddonAddEditSuccess,
  depositAddonAddEditStart,
  getAddonReportingDataStart,
  getAddonReportingDataSuccess,
  getAddonReportingDataFailure,
  getCasinoLogsDataStart,
  getCasinoLogsDataSuccess,
  getCasinoLogsDataFailure,
  getErrorLogsDataStart,
  getErrorLogsDataSuccess,
  getErrorLogsDataFailure,
  getAllUserMigrationDataStart,
  getAllUserMigrationDataSuccess,
  getAllUserMigrationDataFailure,
  getAllRollTransactionsDataStart,
  getAllRollTransactionsDataSuccess,
  getAllRollTransactionsDataFailure,
  promotionCashAddEditFailure,
  promotionCashAddEditSuccess,
  promotionCashAddEditStart,
  sportsProviderUpdateFailure,
  sportsProviderUpdateSuccess,
  sportsProviderUpdateStart,
  getAllSportsbookProvidersFailure,
  getAllSportsbookProvidersSuccess,
  getAllSportsbookProvidersStart
} from '../redux-slices/admins'

import { toast } from '../../components/Toast'
import { getAllClientsStart } from '../redux-slices/tenants'
import { getAllTenantAdminsStart } from '../redux-slices/tenantsFetchData'
import { cloneDeep } from 'lodash'
import { getAllTABonusStart } from '../redux-slices/bonus'
import { getUserStart } from '../redux-slices/fetchData'

export default function * adminssWatcher () {
  yield takeLatest(updateUserPasswordStart.type, updateUserPasswordWorker)
  yield takeLatest(getAllAdminsStart.type, getAllAdminsWorker)
  yield takeLatest(getAdminDetailsStart.type, getAdminDetailsWorker)
  yield takeLatest(getSuperAdminDetailsStart.type, getSuperAdminDetailsWorker)
  yield takeLatest(getAffiliateAllDetailsStart.type, getAffiliateAllDetailsWorker)
  yield takeLatest(updateTenantAdminStatusStart.type, updateTenantAdminStatusWorker)
  yield takeLatest(getAllBonusStart.type, getBonusListingWorker)
  yield takeLatest(getAdminChildrenStart.type, getAdminChildrenWorker)
  yield takeLatest(getAdminDataStart.type, getAdminDataWorker)
  yield takeLatest(addUserInternalTagtStart.type, addInternalUserTagWorker)
  yield takeLatest(updateReorderBonusStart.type, updateReorderBonusWorker)
  yield takeLatest(getBannerTypesStart.type, getBannerTypesWorker)
  yield takeLatest(getCasinoReportingDataStart.type, getCasinoReportingDataWorker)
  yield takeLatest(getUserReportingDataStart.type, getUserReportingDataStartWorker)
  yield takeLatest(getAddonReportingDataStart.type, getAddonReportingDataWorker)
  yield takeLatest(getAllUserHistoryStart.type, getAllUserHistoryWorker)
  yield takeLatest(getSecurityReportStart.type, getSecurityReportWorker)
  yield takeLatest(superadminUpdateUserPasswordStart.type, superadminUpdateUserPasswordWorker)
  yield takeLatest(updateEntityStart.type, updateEntityWorker)
  yield takeLatest(depositAddonAddEditStart.type, depositAddonAddEditWorker)
  yield takeLatest(getCasinoLogsDataStart.type, getCasinoLogsDataWorker)
  yield takeLatest(getErrorLogsDataStart.type, getErrorLogsDataWorker)
  yield takeLatest(getAllUserMigrationDataStart.type, getAllUserMigrationDataWorker)
  yield takeLatest(getAllRollTransactionsDataStart.type, getAllRollTransactionDataWorker)
  yield takeLatest(promotionCashAddEditStart.type, promotionConfigAddEditWorker)
  yield takeLatest(sportsProviderUpdateStart.type, sportsProviderUpdateWorker)
  yield takeLatest(getAllSportsbookProvidersStart.type, getAllSportsProviderWorker)
}

function * updateEntityWorker (action) {
  try {
    const {
      formData,
      setUpdateEntitiyForm
    } = action && action.payload
    const { data } = yield updateEntity(formData)
    yield put(updateEntitySuccess(data?.data?.message))
    setUpdateEntitiyForm()
    toast(data?.data?.message, 'success')
    yield put(getUserStart({ userId: formData.userId }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateEntityFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateUserPasswordWorker (action) {
  try {
    const {
      dataForm,
      handleClose
    } = action && action.payload
    const {
      data
    } = yield updateUserPasswordRequest(
      dataForm.action === 'REJECT'
        ? { ...dataForm, newPassword: btoa(dataForm.newPassword), withdrawalPassword: btoa(dataForm.withdrawalNewPassword) }
        : { ...dataForm, newPassword: btoa(dataForm.newPassword), withdrawalPassword: btoa(dataForm.withdrawalNewPassword) }
    )
    yield put(updateUserPasswordSuccess(data?.data?.message))

    toast(data?.data?.message, 'success')
    yield handleClose()
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateUserPasswordFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * superadminUpdateUserPasswordWorker (action) {
  try {
    const {
      dataForm
    } = action && action.payload
    const {
      data
    } = yield superadminUpdatePassword(dataForm.action === 'REJECT' ? { ...dataForm } : { ...dataForm, newPassword: btoa(dataForm.newPassword), withdrawalPassword: btoa(dataForm.withdrawalNewPassword) })
    yield put(superadminUpdateUserPasswordSuccess(data?.data?.message))
    toast(data?.data?.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(superadminUpdateUserPasswordFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllUserHistoryWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getAllUserHistory(form)
    yield put(getAllUserHistorySuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, e?.response?.data?.errors[0]?.description)
    yield put(getAllUserHistoryFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getSecurityReportWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getSecurityReport(form)
    yield put(getSecurityReportSuccess(data?.data?.securityDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, e?.response?.data?.errors[0]?.description)
    yield put(getSecurityReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getUserReportingDataStartWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getUserReportingData(form)
    yield put(getUserReportingDataSuccess(data?.data?.walletTransactions))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getUserReportingDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAddonReportingDataWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getAddonReportingData(form)
    yield put(getAddonReportingDataSuccess(data?.data?.users))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAddonReportingDataFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getCasinoReportingDataWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getCasinoReportingData(form)
    yield put(getCasinoReportingDataSuccess(data?.data?.casinoTransactionDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getCasinoReportingDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

const getAdminState = (state) => state.admins

function * getAllAdminsWorker (action) {
  try {
    const { limit, pageNo, orderBy, search, sort, superAdminId, superRoleId, status = '' } =
      action && action.payload

    const { data } = yield getAllAdmins({
      limit,
      pageNo,
      orderBy,
      search,
      sort,
      superAdminId,
      superRoleId,
      status
    })

    yield put(getAllAdminsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllAdminsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getBannerTypesWorker () {
  try {
    const { data } = yield getBannerTypes()
    yield put(getBannerTypesSuccess(data?.data?.bannerKey))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBannerTypesFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getAdminDetailsWorker (action) {
  try {
    const { adminId } = action && action.payload

    const { data } = yield getAdmin({ adminId })

    yield put(getAdminDetailsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getSuperAdminDetailsWorker (action) {
  try {
    const { isTenant = false, onStart = false } = action && action.payload
    const { data } = isTenant ? yield getTenantOwnerDetails() : yield getSuperAdminDetails()

    yield put(getSuperAdminDetailsSuccess(data?.data?.adminDetails))
    if (onStart) yield put(getLoggedSADetailsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSuperAdminDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getAffiliateAllDetailsWorker (action) {
  try {
    const { isAgent, isAffiliate } = action && action?.payload
    const { data } = yield getAffiliateAllDetails({ isAgent, isAffiliate })
    yield put(getAffiliateAllDetailsSuccess({ data: isAgent ? data?.data?.agentDetails : data?.data?.affiliateDetails, isAgent }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAffiliateAllDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAdminDataWorker (action) {
  try {
    const { adminId } = action && action.payload

    const { data } = yield getAdmin({ adminId })

    yield put(getAdminDataSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTenantAdminStatusWorker (action) {
  try {
    const {
      adminId,
      status,
      orderBy,
      sort,
      search,
      isTenant = false,
      active = ''
    } = action && action.payload

    isTenant
      ? yield tenantViewToggleStatus({ adminId, status, code: 'ADMIN' })
      : yield superAdminViewToggleStatus({ adminId, status, code: 'ADMIN' })

    yield put(updateTenantAdminStatusComplete())

    isTenant
      ? yield put(getAllTenantAdminsStart({ limit: 15, pageNo: 1, sort, orderBy, search, status: active }))
      : yield put(
        getAllClientsStart({
          limit: 15,
          pageNo: 1,
          sort,
          orderBy,
          search,
          status: active
        })
      )

    yield toast('Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateTenantAdminStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getBonusListingWorker (action) {
  try {
    const { data } = yield getAllBonus(action.payload)
    yield put(getAllBonusComplete(data?.data?.bonusList))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllBonusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAdminChildrenWorker (action) {
  try {
    const { superAdminId, superRoleId } =
      action && action.payload
    const { adminChildren } = yield select(getAdminState)

    const addChildrenToAdmin = (newAdminChildren, id, children) => {
      if (newAdminChildren?.id === id) {
        return (newAdminChildren.children = [...children])
      }

      if (newAdminChildren?.children?.length) {
        for (const admin of newAdminChildren.children) {
          addChildrenToAdmin(admin, id, children)
        }
      }
    }

    const { data } = yield getAdminChildren({ superAdminId, superRoleId })

    const newAdminChildren = cloneDeep(adminChildren)
    const children = data?.data?.adminDetails?.map((item) => {
      return {
        id: item.superAdminUserId,
        name: `${item.firstName || ''} (${item.childCount})`,
        children: [],
        data: item
      }
    })
    yield addChildrenToAdmin(newAdminChildren, superAdminId, children)

    yield put(getAdminChildrenSuccess(newAdminChildren))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getAdminChildrenFailure())
  }
}

function * addInternalUserTagWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    const res = yield addInternalTag({ data, isTenant })
    yield toast(res.data.message, 'success')
    yield put(addUserInternalTagtSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(addUserInternalTagtFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateReorderBonusWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    const res = yield reorderBonus({ data, isTenant })
    yield toast(res?.data?.message, 'success')
    if (data?.adminId) {
      yield put(getAllBonusStart({ adminId: data?.adminId, tenantId: data?.tenantId, reorder: true }))
    } else {
      yield put(getAllTABonusStart({
        limit: '',
        pageNo: '',
        search: '',
        bonusType: '',
        isActive: '',
        reorder: true
      }))
    }
    yield put(updateReorderBonusComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateReorderBonusComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * depositAddonAddEditWorker (action) {
  try {
    const { data, isEdit } = action && action.payload
    let res = null
    if (isEdit) {
      res = yield depositAddonTypeEdit({ data })
    } else {
      res = yield depositAddonTypeAdd({ data })
    }
    yield toast(res?.data?.data?.message, 'success')
    yield put(depositAddonAddEditSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(depositAddonAddEditFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCasinoLogsDataWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getCasinoLogsData(form)
    yield put(getCasinoLogsDataSuccess(data?.data?.casinoLogHistoryDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getCasinoLogsDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getErrorLogsDataWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getErrorLogsData(form)
    yield put(getErrorLogsDataSuccess(data?.data?.errorLogHistoryDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getErrorLogsDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllUserMigrationDataWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getUserMigratedData(form)
    yield put(getAllUserMigrationDataSuccess(data?.data?.migratedUserData))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAllUserMigrationDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllRollTransactionDataWorker (action) {
  try {
    const {
      form
    } = action && action.payload
    const { data } = yield getAllRollTransactions(form)
    yield put(getAllRollTransactionsDataSuccess(data?.data?.rollTransactionData))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAllRollTransactionsDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * promotionConfigAddEditWorker (action) {
  try {
    const { data } = action && action.payload

    const res = yield promotionCashAddUpdate({ data })

    yield toast(res?.data?.data?.message, 'success')
    yield put(promotionCashAddEditSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(promotionCashAddEditFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllSportsProviderWorker (action) {
  try {
    const { form } = action && action.payload

    const { data } = yield getAllSportsProvider({ data: form })
    yield put(getAllSportsbookProvidersSuccess(data?.data?.providers))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAllSportsbookProvidersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * sportsProviderUpdateWorker (action) {
  try {
    const { form, callBack } = action && action.payload

    const res = yield updateSportsProvider({ data: form })
    callBack()

    yield toast(res?.data?.data?.message, 'success')
    yield put(sportsProviderUpdateSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(sportsProviderUpdateFailure(e?.response?.data?.errors[0]?.description))
  }
}
