// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.depositType-select-tenant {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/Super-Admin/SportsPromotionCashConfig/depositAddonConfig.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AACF","sourcesContent":[".depositType-select-tenant{\r\n  min-height: 200px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  color: red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
