export const casinoReportingTable = (isV2 = false) => {
  if (isV2) {
    return [
      { label: 's.no', value: '' },
      { label: 'Username', value: 'username' },
      { label: 'game name', value: 'name' },
      { label: 'betResult', value: 'actionType' },
      { label: 'casinoTransactionId', value: 'casinoTransactionId' },
      { label: 'subCategory', value: 'subCategory' },
      { label: 'category', value: 'category' },
      { label: 'provider', value: 'provider' },
      { label: 'nonWithdrawableWallet', value: 'nonWithdrawableWallet' },
      { label: 'withdrawableWallet', value: 'withdrawableWallet' },
      { label: 'promotionCashWallet', value: 'promotionCashWallet' },
      { label: 'bonusWallet', value: 'bonusWallet' },
      { label: 'payoutWallet', value: 'payoutWallet' },
      { label: 'nonWithrawableRolledAmount', value: 'nonWithdrawableRolledAmount' },
      { label: 'bonusRolledDetails', value: 'bonusRollDetails' },
      { label: 'promotionRolledDetails', value: 'promotionRollDetails' },
      { label: 'seasonalBenefit', value: 'seasonalBenefit' },
      { label: 'voucherbenifits', value: 'voucherbenifits' },
      { label: 'giftCardBenifits', value: 'giftCardBenifits' },
      { label: 'beforeMoney', value: 'beforeMoney' },
      { label: 'afterMoney', value: 'afterMoney' },
      { label: 'transaction date', value: 'transactionDate' },
      { label: 'transaction time', value: 'transactionTime' }
    ]
  }

  return [
    { label: 's.no', value: '' },
    { label: 'username', value: 'username' },
    { label: 'gameName', value: 'name' },
    // { label: 'betResult', value: 'actionType' },
    // { label: 'casinoTransactionId', value: 'casinoTransactionId' },
    { label: 'category', value: 'category' },
    { label: 'subCategory', value: 'subCategory' },
    { label: 'provider', value: 'provider' },
    { label: 'amount', value: 'amount' },
    { label: 'superadminNotes', value: 'superadminNotes' },
    { label: 'seniorNotes', value: 'seniorNotes' },
    { label: 'transactionType', value: 'actionId' },

    { label: 'createdAt', value: 'createdAt' }
  ]
}

export const userReportingTable = [
  { label: 's.no', value: '' },
  { label: 'userName', value: 'username' },
  { label: 'amount', value: 'amount' },
  { label: 'transactionType', value: 'actionType' },
  { label: 'createdAt', value: 'createdAt' }
]

export const rollingReportingTable = [
  { label: 's.no', value: '' },
  { label: 'contestName', value: 'contestName' },
  { label: 'registerUser', value: 'registerUser' },
  { label: 'targetAmount', value: 'targetAmount' },
  { label: 'currentAmount', value: 'currentAmount' },
  { label: 'createdAt', value: 'actionType' },
  { label: 'updatedAt', value: 'createdAt' }
]

export const sportsReportingTable = (isBetBy) => {
  if (isBetBy) {
    return [
      { label: 'sNo', value: 'sNo' },
      { label: 'betSlipId', value: 'betSlipId' },
      { label: 'username', value: 'userName' },
      { label: 'betType', value: 'betType' },
      { label: 'stake', value: 'catgeory' },
      { label: 'price', value: 'price' },
      { label: 'possibleWin', value: 'possibleWin' },
      { label: 'betSettlementStatus', value: 'betSettlementStatus' },
      { label: 'betSlipStatus', value: 'betSlipStatus' },
      { label: 'date', value: 'date' }
    ]
  } else {
    return [
      { label: 'sNo', value: 'sNo' },
      { label: 'betSlipId', value: 'betSlipId' },
      { label: 'username', value: 'userName' },
      { label: 'betType', value: 'betType' },
      { label: 'stake', value: 'catgeory' },
      { label: 'price', value: 'price' },
      { label: 'possibleWin', value: 'possibleWin' },
      { label: 'betSettlementStatus', value: 'betSettlementStatus' },
      { label: 'betSlipStatus', value: 'betSlipStatus' },
      { label: 'mtsStatus', value: 'mtsStatus' },
      { label: 'date', value: 'date' },
      { label: 'action', value: 'action' }
    ]
  }
}
export const sportsTransactionsTable = (isV2User, isBetBy) => {
  if (isV2User) {
    return [
      { label: 'sNo', value: '' },
      { label: 'username', value: 'casinoTransactionId' },
      { label: 'amount', value: 'amount' },
      { label: 'journalEntry', value: 'catgeory' },
      { label: 'status', value: 'subCategory' },
      { label: 'beforeBalance', value: 'casinoTransactionId' },
      { label: 'currentBalance', value: 'provier' },
      { label: 'nonCashAmount', value: 'amount' },
      { label: 'bonusWallet', value: 'bonusWallet' },
      { label: 'payoutWallet', value: 'payoutWallet' },
      { label: 'description', value: 'description' },
      { label: 'date', value: 'transactionDate' }
    ]
  } else if (isBetBy) {
    return [
      { label: 'sNo', value: '' },
      { label: 'username', value: 'casinoTransactionId' },
      { label: 'journalEntry', value: 'catgeory' },
      { label: 'status', value: 'subCategory' },
      { label: 'beforeBalance', value: 'casinoTransactionId' },
      { label: 'currentBalance', value: 'provier' },
      { label: 'bonusWallet', value: 'bonusWallet' },
      { label: 'payoutWallet', value: 'payoutWallet' },
      { label: 'nonWithdrawableWallet', value: 'nonWithdrawableWallet' },
      { label: 'withdrawableWallet', value: 'withdrawableWallet' },
      { label: 'promotionCashWallet', value: 'promotionCashWallet' },
      { label: 'description', value: 'description' },
      { label: 'date', value: 'transactionDate' }
    ]
  } else {
    return [
      { label: 'sNo', value: '' },
      { label: 'username', value: 'casinoTransactionId' },
      { label: 'amount', value: 'amount' },
      { label: 'journalEntry', value: 'catgeory' },
      { label: 'status', value: 'subCategory' },
      { label: 'beforeBalance', value: 'casinoTransactionId' },
      { label: 'currentBalance', value: 'provier' },
      { label: 'nonCashAmount', value: 'amount' },
      { label: 'description', value: 'amount' },
      { label: 'date', value: 'transactionDate' }
    ]
  }
}

export const casinoLogTable = [
  { label: 'sNo', value: '' },
  { label: 'username', value: 'username' },
  { label: 'game', value: 'name' },
  { label: 'category', value: 'category' },
  { label: 'subcategory', value: 'subcategory' },
  { label: 'logType', value: 'log Type' },
  { label: 'requestBody', value: 'requestBody' },
  { label: 'description', value: 'description' },
  { label: 'transactionId', value: 'transactionId' },
  { label: 'gameRoundId', value: 'Game Round Id' },
  { label: 'date', value: 'Created At' }
]

export const errorLogTable = (isWalletLogs) => {
  return !isWalletLogs
    ? [
        { label: 'sNo', value: 'sNo' },
        { label: 'username', value: 'username' },
        { label: 'errorCode', value: 'errorCode' },
        { label: 'errorMessage', value: 'errorMessage' },
        { label: 'requestBody', value: 'requestBody' },
        { label: 'requestUrl', value: 'requestUrl' },
        { label: 'date', value: 'createdAt' }

      ]
    : [
        { label: 'sNo', value: 'sNo' },
        { label: 'user', value: 'user' },
        { label: 'logType', value: 'logType' },
        { label: 'description', value: 'description' },
        { label: 'casinoTransactionId', value: 'casinoTransactionId' },
        { label: 'transactionType', value: 'transactionType' },
        { label: 'walletRequestId', value: 'walletRequestId' },
        { label: 'previousData', value: 'previousData' },
        { label: 'updateData', value: 'updateData' },
        { label: 'createdAt', value: 'createdAt' },
        { label: 'tenant', value: 'tenant' }
      ]
}
