export const JA =
{
  1: 1,
  accept: '受け入れる',
  accepted: '承認されました',
  amountShouldBeInAccordingToRank: '金額はランクに従う必要があります。',
  acceptRequest: '要請を受け入れます',
  accountActions: 'アカウントのアクション',
  accountNumber: '口座番号',
  action: 'アクション',
  actions: '行動',
  actionType: 'アクションの種類',
  active: 'アクティブ',
  address: '住所',
  addressMax: '最大100文字',
  addressRequired: '住所が必要です',
  adminIdRequired: '親管理者が必要です',
  adminName: '管理者名',
  all: '全て',
  allowedCurrencies: '使用できる通貨',
  allowedLanguages: '許可される言語',
  allowedProviders: '許可されたプロバイダー',
  amount: '額',
  amountCheck: '金額は利用可能な残高よりも少ない必要があります',
  amountMin: '金額は0より大きくなければなりません',
  amountPositive: '金額は正の値である必要があります',
  amountRequired: '必要量',
  amountShouldBeGreaterThan0: '金額は0より大きくなければなりません',
  amountLength: '金額は7桁未満である必要があります。',
  approve: '承認する',
  approved: '承認された',
  approvedKyc: '承認されたKYC',
  areYouSureActionRequest: '本当によろしいですか <ReferenceError: action is not defined> リクエスト？',
  areYouSureKycRequest: '本当によろしいですか <ReferenceError: action is not defined> KYCリクエスト？',
  associatedReferrals: '関連する紹介',
  associateReferral: 'アソシエイトの紹介',
  bankName: '銀行名',
  bannerManagement: 'バナー管理',
  rankingSeasons: 'ランキングシーズン',
  bannerPreview: 'バナーのプレビュー',
  bannerRequired: 'バナーが必要です',
  bannerTypeRequired: 'バナーの種類は必須です',
  basicInfo: '基本情報',
  Bet: 'ベット',
  BET: 'ベット',
  betHistory: 'ベット履歴',
  betLevel: 'ベットレベル',
  betLevelMustBeAnIntegerValue: 'ベットレベルは整数値である必要があります',
  betLevelMustBeAPositiveNumber: 'ベットレベルは正の数である必要があります',
  betLevelRequired: '必要なベットレベル',
  betResult: 'ベット結果',
  betType: 'ベットタイプ',
  blackList: 'ブラックリスト',
  blacklist: 'ブラックリスト',
  blacklistDescription: 'ブラックリストの説明',
  blackListPlayer: 'ブラックリストプレーヤー',
  blacklistReason: 'ブラックリストの理由',
  blacklistStatus: 'ブラックリストのステータス',
  bonus: 'ボーナス',
  Bonus: 'ボーナス',
  bonus_limit: 'ボーナス限度額',
  bonus_percentage: 'ボーナスの割合',
  bonus_type: 'ボーナスの種類',
  bonus_valid_from: 'ボーナスの有効期限',
  bonus_valid_to: 'ボーナスの有効期限',
  bonusLimit: 'ボーナス限度額',
  bonusPercentage: 'ボーナスの割合',
  bonusPercentageRequired: '必要なボーナスの割合',
  bonusPercentMustBeNumber: 'ボーナスのパーセンテージは数値でなければなりません',
  bonusRequired: 'ボーナスが必要です',
  bonusRollingPercentage: 'ボーナスローリングパーセンテージ',
  bonusRollingPercentNumber: 'ボーナス ローリング パーセントは数値である必要があります',
  bonusTransactions: 'ボーナストランザクション',
  bonusType: 'ボーナスの種類',
  bonusTypeRequired: 'ボーナスの種類が必要です',
  bonusUsers: 'ボーナスユーザー',
  bonusValidFrom: 'ボーナスの有効期限',
  bonusValidTo: 'ボーナスの有効期限',
  cancel: 'キャンセル',
  cancelReRequested: 'キャンセル - 再リクエスト',
  cash: '現金',
  Cash: '現金',
  'Cash + Bonus': '現金ボーナス',
  cashAmount: '現金金額',
  casinoCategories: 'カジノカテゴリー',
  casinoGame: 'カジノゲーム',
  casinoGames: 'カジノ ゲーム',
  casinoManagement: 'カジノ管理',
  casinoProviderIdRequired: 'カジノプロバイダーIDが必要です',
  casinoProviders: 'カジノプロバイダー',
  casinoReporting: 'カジノのレポート',
  casinoSubCategories: 'カジノのサブカテゴリ',
  casinoTransaction: 'カジノ取引',
  casinoTransactionId: 'カジノトランザクションID',
  categories: 'カテゴリー',
  category: 'カテゴリー',
  categoryRequired: 'カテゴリ必須',
  changeLanguage: '言語を変えてください',
  chooseNote: '何も選択しない場合 {{tab}}、すべての人のために作成されます {{tab}} デフォルトでは',
  chooseType: 'タイプを選択してください',
  city: '市',
  cityMax: '最大50文字',
  cityRequired: '市区町村は必須です',
  cms: 'CMS',
  CMS: 'CMS',
  compensation_target: '報酬対象',
  compensationBonus: '報酬ボーナス',
  compensationDepositBonus: '補償入金ボーナス',
  compensationTarget: '報酬対象',
  compensationTargetRequired: '報酬目標が必要です',
  Complete: '完了しました',
  confirm: '確認する',
  CONFIRM: '確認する',
  confirmAction: '本当によろしいですか?',
  confirmDelete: '消去してもよろしいですか ？',
  confirmMarkStatus: '本当にマークしますか?  ',
  confirmPasswordRequired: 'パスワードの確認が必要です!',
  confirmResetEmail: 'にパスワード リセット電子メールを送信してもよろしいですか?',
  confirmSendNotification: '通知を送信してもよろしいですか?',
  confirmSwitch: '別の言語に切り替えてもよろしいですか?',
  confirmTitle: '本気ですか？',
  confirmTransactionLimit: '本当によろしいですか {{buttonTitle}} {{amount}} {{currency}}?',
  content: 'コンテンツ',
  contentRequired: '必須のコンテンツ',
  contentValidFormat: '必須のコンテンツ',
  copiedToClipboard: 'クリップボードにコピーされました',
  copyImageURL: 'この画像の URL をクリップボードにコピーします',
  country: '国',
  countryCode: '国コード',
  countryCodeRequired: '国コードが必要です',
  countryName: '国名・クラブ名',
  countryRequired: '国は必須です',
  couponNumber: 'クーポン番号',
  create: '作成する',
  createBonus: 'ボーナスの作成',
  createCMS: 'CMSの作成',
  created: '作成した',
  createdAt: '作成日',
  createForAll: 'どの {placeholder} も選択しない場合、デフォルトですべての {placeholder} に対して作成されます',
  createNotification: '通知の作成',
  createTenant: 'テナントの作成',
  CREDIT: 'クレジット',
  currency: '通貨',
  currencyRequire: '必要な通貨',
  currencyRequired: '必要な通貨',
  currentOnlinePlayer: '現在のオンラインプレイヤー',
  daily: '毎日',
  dailyLimit: '1日あたりの制限',
  dashboard: 'ダッシュボード',
  date: '日付',
  dateEnd: '終了日',
  dateOfBirth: '生年月日',
  dateOfBirthInvalid: '正しい誕生日を入力してください',
  dateOfBirthMax: '生年月日は今日より前の日付である必要があります',
  dateStart: '開始日',
  DEBIT: 'デビット',
  demoMode: 'デモモード',
  deposit: 'デポジット',
  depositAccept: 'デポジットの受け入れ',
  depositers: '預金者',
  depositersAmount: '預金者金額',
  depositors: '預金者',
  depositReject: '入金拒否',
  description: '説明',
  discardChanges: '言語を切り替えると、行ったすべての変更が破棄されます。',
  DOB: '生年月日',
  domain: 'ドメイン',
  domainName: 'ドメイン名',
  downloadAsCSV: 'CSVとしてダウンロード',
  downloadAsCsv: 'CSVとしてダウンロード',
  downloadCsv: 'CSVとしてダウンロード',
  duplicates: '重複',
  edit: '編集',
  editProvider: 'プロバイダーの編集',
  email: 'Eメール',
  emailRequired: 'メールアドレスが必要です',
  emergency: '緊急',
  endDate: '終了日',
  english: 'English',
  enterAccountNumber: '口座番号を入力してください',
  enterAmount: '金額を入力します',
  enterBetLevel: 'ベットレベルを入力してください',
  enterBonusPercentage: 'ボーナスパーセンテージ%を入力してください',
  enterCasinoProviderName: 'カジノプロバイダー名を入力してください',
  enterCompensationTarget: '報酬目標を入力してください',
  enterCorrectURL: '正しいURLを入力してください！',
  enterCurrency: '通貨を入力してください',
  enterDailyLimit: '1 日あたりの制限を入力してください',
  enterDescription: '説明を入力してください',
  enterDomain: 'ドメインを入力してください',
  enterEmail: 'メールアドレスを入力して',
  enterFirstName: '名を入力してください',
  enterGameName: 'ゲーム名を入力してください',
  enterLastName: '姓を入力してください',
  enterMaxBonusLimit: 'ボーナスの最大限度額を入力してください',
  enterMinBalancePercentage: '最小残高パーセンテージ % を入力してください',
  enterMinutesForBonusGiven: '付与されるボーナスの分を入力してください',
  enterMonthlyLimit: '月間制限を入力してください',
  enterName: '名前を入力',
  enterOperatorGameId: 'オペレーターのゲーム ID を入力してください',
  enterPassword: 'パスワードを入力する',
  enterReocurringDepositRate: '定期預金金利 % を入力してください',
  enterRollingPercent: 'ローリングパーセントを入力してください',
  editDetails: '詳細を編集する',
  enterSlug: 'ナメクジを入力してください',
  enterTitle: 'タイトルを入力してください',
  enterUsername: 'ユーザーネームを入力してください',
  enterValidAmount: '有効な金額を入力してください',
  enterValidPhoneNumberWithCountryCode: '有効な電話番号を国コードとともに入力してください',
  enterWeeklyLimit: '週間制限を入力してください',
  enterWithdrawalRate: '出金率を入力してください',
  enterWithdrawLimit: '出金制限金額を入力',
  Failed: '失敗した',
  fileFormatError: 'アップロードされたファイルの形式はサポートされていません。',
  fileFormatValidation: 'アップロードされたファイルはサポートされていない形式です',
  fileRequired: 'ファイルが必要です',
  fileSizeLessThan1MB: 'ファイルサイズは1MB未満である必要があります',
  fileSizeShouldBeLessThan1MB: 'ファイルサイズは1MB未満である必要があります',
  fileSizeValidation: 'ファイルサイズは1MB未満である必要があります',
  firstDepositors: '初回預金者',
  firstName: 'ファーストネーム',
  firstNameFormat: 'アルファベットとスペースのみが使用でき、アルファベットで始める必要があります',
  firstNameMustBeAtleast3Characters: '名は少なくとも 3 文字である必要があります',
  firstNameMustBeLessThan25Characters: '名は 25 文字未満である必要があります',
  firstNameRequired: '名前は必須です!',
  friday: '金曜日',
  from: 'から',
  fullName: '正式名称',
  gallery: 'ギャラリー',
  game: 'ゲーム',
  gameId: 'ゲームID',
  gameName: 'ゲーム名',
  gameNameRequired: 'ゲーム名は必須です',
  games: 'ゲーム',
  gameSubCategory: 'ゲームのサブカテゴリ',
  gender: '性別',
  genderRequired: '性別必須',
  goBack: '戻る',
  greenFlag: 'グリーンフラッグ',
  greenflag: 'グリーンフラッグ',
  groupNameFormat: 'アルファベット、数字、スペースのみ使用可能',
  groupNameRequired: 'グループ名は必​​須です',
  hotTimeBonus: 'ホットタイムボーナス',
  id: 'ID',
  imageHeightError: '画像の高さは次の範囲である必要があります。 {{minRequiredHeight}} ピクセル - {{maxRequiredHeight}} ピクセル！',
  imageWidthError: '画像の幅は次の範囲である必要があります。 {{minRequiredWidth}}} ピクセル - {{maxRequiredWidth}} ピクセル！',
  inActive: '非活性',
  inactive: '非活性',
  instigator: '扇動者',
  invalidAccountNumber: '無効な口座番号',
  invalidDate: '無効な日付',
  invalidEmail: '無効な電子メール',
  invalidPassword: '無効なパスワード',
  ipAddress: 'IPアドレス',
  isActive: 'アクティブです',
  isExpired: '有効期限が切れています',
  japanese: '日本語',
  korean: '韓国語',
  koreanWon: '韓国ウォン',
  kycDetails: 'KYCの詳細',
  kycMethod: 'Kycメソッド',
  kycSettings: 'KYC設定',
  kycStatus: 'Kycステータス',
  language: '言語',
  languageCode: '言語コード',
  languageModule: '言語モジュール',
  languageName: '言語名',
  languages: '言語',
  lastName: '苗字',
  lastNameFormat: 'アルファベットとスペースのみが使用でき、アルファベットで始める必要があります',
  lastNameMustBeAtleast3Characters: '姓は少なくとも 3 文字である必要があります',
  lastNameMustBeLessThan25Characters: '姓は 25 文字未満である必要があります',
  lastNameRequired: '姓は必須です!',
  limitMustBeAnInteger: '制限は整数でなければなりません',
  limitMustBePositiveNumber: '制限は正の数である必要があります',
  limitRequired: '必要な制限',
  loggedInCount: 'ログイン数',
  logo: 'ロゴ',
  logout: 'ログアウト',
  Lost: '失った',
  LOST: '失った',
  maintenance: 'メンテナンス',
  masterCasinoCategories: 'マスターカジノのカテゴリー',
  masterId: 'マスターID',
  max16Characters: '最大16文字',
  maxBonusLimitRequired: '必要なボーナスの最大限度額',
  maxCharacters: '{{label}} 最大でも {{maxNumber}} キャラクター',
  maxDigits: '{{label}} 未満である必要があります {{maxNumber}} 数字',
  maximum_bonus_limit: 'ボーナスの上限額',
  maximumBonusLimit: 'ボーナスの上限額',
  maxNumber: 'マックス {{maxNumber}} 番号',
  message: 'メッセージ',
  messagePlaceholder: 'メッセージを入力してください',
  min1LowerCase: '最小 1 小文字',
  min1Number: '最小 1 の数値',
  min1SpecialCharacters: '最小 1 個の特殊文字',
  min1UpperCase: '最小 1 大文字',
  min4Characters: '最小 4 文字',
  min_balance_percentage: '最小残高パーセンテージ',
  minAge: '18歳以上であること',
  minBalancePercentage: '最小残高パーセンテージ',
  minCharacters: '{{label}} 少なくとも {{minNumber}} キャラクター',
  minDigits: '{{label}} 少なくとも {{minNumber}} 数字',
  minimum1BetLevelRequired: '最低 1 ベット レベルが必要です',
  minimum1SpinRequired: '最低1スピン必要',
  minLowerCase: '最小 1 小文字',
  minNumber: '最小 1 の数値',
  minSpecialCharacters: '最小 1 個の特殊文字',
  minUpperCase: '最小 1 大文字',
  minutes_for_bonus_given: '与えられるボーナスの分数',
  minutesForBonusGiven: 'ボーナスの付与分数',
  minutesForBonusGivenRequired: 'ボーナス付与に必要な分数',
  monday: '月曜日',
  monthly: '毎月',
  monthlyLimit: '月間限度額',
  monthMustBeAnInteger: '月は整数でなければなりません',
  monthMustBePositiveNumber: '月は正の数である必要があります',
  monthRequired: '必須月',
  moreInfo: 'より詳しい情報',
  mustBeAtLeast8Characters: '少なくとも 8 文字と、大文字 1 文字、小文字 1 文字、特殊文字 1 文字、数字 1 文字を含める必要があります',
  mustBeAtLeastCharacters: '{{label}} 少なくとも {{minNumber}} キャラクター',
  mustBeAtMostCharacters: '{{label}} 最大でも {{maxNumber}} キャラクター',
  mustBeGreaterThan: 'より大きくなければなりません',
  mustBeGreaterThanOrEqualTo: '{value} 以上である必要があります',
  mustBeGreaterThanZero: '金額は正の値である必要があります',
  mustBeLesserThanCharacters: '未満である必要があります {{maxNumber}} キャラクター!!',
  mustBeNumber: '数字でなければなりません',
  mustBeRealAmount: '実額でなければなりません',
  na: 'NA',
  name: '名前',
  NEW: '新しい',
  newPasswordRequired: '新しいパスワードが必要です!',
  next: '次',
  no: 'いいえ',
  noDataFound: '何もデータが見つかりませんでした',
  noImageFound: '画像が見つかりません',
  nonCashAmount: '現金以外の金額',
  sportsPromotionCash: 'スポーツ奨励金',
  casinoPromotionCash: 'カジノのプロモーション現金',
  nonDepositors: '非預金者',
  none: 'なし',
  nonRequest: '非リクエスト',
  noProvidersAvailable: '利用可能なプロバイダーがありません',
  normal: '普通',
  notAllowedToView: 'このコンテンツの閲覧は許可されていませんでした',
  note: '注記：',
  notFound: '見つかりません',
  notification: '通知',
  notificationCategory: '通知カテゴリ',
  notificationCategoryMessageRequired: '通知カテゴリ メッセージは必須です',
  notificationCategoryRequired: '通知カテゴリは必須です',
  notificationCenter: '通知センター',
  notificationMessage: '通知メッセージ',
  notificationMessageRequired: '通知メッセージが必要です',
  notificationRecipients: '通知の受信者',
  notificationRequired: '通知が必要です',
  OLD: '古い',
  oldAndNewPasswordMustBeDifferent: '古いパスワードと新しいパスワードは異なる必要があります。',
  oldPasswordRequired: '古いパスワードが必要です!',
  onlinePlayer: 'オンラインプレイヤー',
  onlineUsersDemograph: 'オンライン ユーザーの人口統計',
  onlyAlphabets: 'アルファベットのみ使用可能',
  onlyAlphabetsAllowed: 'アルファベットのみ使用可能',
  onlyAlphabetsAndNumbers: 'アルファベットと数字のみ使用可能',
  onlyAlphabetsAndSingleSpace: 'アルファベットと単語間のスペースは 1 つだけ使用できます',
  onlyAlphabetsNumbersAndUnderscore: 'アルファベット、数字、アンダースコア(_)のみが使用可能',
  onlyAlphabetsSpaceAndDash: 'アルファベット、スペース、ダッシュ(-)のみ使用可能',
  onlyNumbersAllowed: '数字のみを使用できます',
  onlyNumbersAreAllowed: '数字のみが許可されます',
  operatorGameId: 'オペレーターのゲーム ID',
  operatorGameIdRequired: 'オペレーターのゲーム ID が必要です',
  operatorStatus: 'オペレーターのステータス',
  origin: '起源',
  other: '他の',
  overview: '概要',
  owner: '所有者',
  ownerIdRequired: '所有者 ID が必要です',
  ownerName: '所有者名',
  pages: 'ページ',
  password: 'パスワード',
  passwordMatchError: 'パスワードが一致する必要があります',
  passwordRequired: 'パスワードが必要',
  passwordRequirements: 'パスワード要件',
  passwordsMustMatch: 'パスワードが一致する必要があります',
  pending: '保留中',
  Pending: '保留中',
  pendingKyc: '保留中のKYC',
  percentGreaterThanEqual: '% 以上である必要があります <ReferenceError: percent is not defined>',
  perPage: '1ページあたり',
  phone: '電話',
  phoneMustBeAtLeast5Digits: '電話番号は少なくとも 5 桁である必要があります',
  phoneMustBeLessThan13Digits: '電話番号は 13 桁未満である必要があります',
  phoneNumber: '電話番号',
  phoneRequired: '電話番号が必要です',
  player: 'プレーヤー',
  playerHistory: 'プレイヤー履歴 (プレイヤーりれき)',
  playerName: 'プレーヤの名前',
  playerReferral: '選手の紹介',
  players: '選手',
  playerStats: 'プレイヤー統計',
  playerWallet: 'プレイヤーウォレット',
  portal: 'ポータル',
  portalName: 'ポータル名',
  preferredLanguageRequired: '希望言語は必須です',
  primaryCurrency: '主要通貨',
  profile: 'プロフィール',
  provider: 'プロバイダー',
  providerName: 'プロバイダー名',
  providers: 'プロバイダー',
  readership: '読者層',
  reason: '理由',
  reasonMax: '最大100文字',
  reasonRequired: '必要な理由',
  recipients: '受信者',
  recurringDepositRate: '定期預金金利',
  redFlag: '赤旗',
  redflag: '赤旗',
  redFlagDescription: '危険信号の説明',
  redFlagReason: '危険信号の理由',
  redflagstatus: '赤旗ステータス',
  referralCode: '紹介コード',
  referralCount: '紹介数',
  referralLink: '紹介リンク',
  referrals: '紹介',
  registrationDate: '登録日',
  registrationDateEnd: '登録終了日',
  registrationDateStart: '登録開始日',
  reject: '拒否する',
  rejected: '拒否されました',
  rejectKyc: 'KYCを拒否する',
  rejectRequest: 'リクエストを拒否する',
  removeAffiliate: 'アフィリエイトを削除',
  reoccuringBonus: '再発生ボーナス',
  reoccurring_deposit_rate: '定期預金金利',
  reoccurringDepositBonus: '再入金ボーナス',
  reporting: '報告',
  request: 'リクエスト',
  requestStatus: 'リクエストステータス',
  required: '{{label}} 必須',
  resetConfirmation: 'リセットしてもよろしいですか <ReferenceError: data is not defined>?',
  resetFilters: 'フィルターをリセットする',
  resetPassword: 'パスワードを再設定する',
  resetPasswordFor: 'のパスワードをリセット',
  roleRequired: '必要な役割',
  RollBack: 'ロールバック',
  rollingPercent: 'ローリングパーセント',
  rollingPercentDescription: 'ローリングパーセンテージの説明',
  saturday: '土曜日',
  search: '検索',
  searchByNameOrEmail: '名前またはメールアドレスで検索',
  searchName: '検索名',
  searchPlaceholder: '検索プレースホルダー',
  searchProvider: '検索プロバイダー',
  searchSubCategory: 'サブカテゴリの検索',
  searchSubCategoryName: '検索サブカテゴリ名',
  searchTitleSlug: 'タイトル、スラッグを検索',
  searchUsername: '検索',
  searchUsernameOrName: 'ユーザー名または名前を検索',
  section: 'セクション',
  seen: '見た',
  seenBy: '読んだ人',
  seenStatus: '見たステータス',
  segmentIdRequired: 'セグメントIDが必要です!',
  select_owner: '所有者の選択',
  selectAtLeastOneDay: '少なくとも 1 日を選択してください',
  selectBank: '銀行を選択してください',
  selectBonus: 'ボーナスを選択してください',
  selectBonusLimit: 'ボーナス限度額を選択してください',
  selectCategory: 'カテゴリを選んでください',
  selectCountry: '国を選択',
  selectCurrency: '通貨を選択してください',
  selectEndDate: '終了日時を選択してください',
  selectEndDateAndTime: '終了日時を選択してください',
  selectEndDateTime: '終了日時を選択してください',
  selectNotificationCategory: '通知カテゴリの選択',
  selectOneCategory: 'カテゴリを 1 つ選択してください',
  selectOneSenior: 'シニアを 1 人選択してください',
  selectOwner: '所有者の選択',
  selectPrimaryCurrency: '主通貨を選択してください',
  selectProvider: 'プロバイダーの選択',
  selectStartDate: '開始日時を選択してください',
  selectStartDateAndTime: '開始日時を選択してください',
  selectStartDateTime: '開始日時を選択してください',
  selectSubCategory: 'サブカテゴリを選択してください',
  selectTenant: 'テナントの選択',
  selectSenior: '「シニアを選択」、',
  seletProvider: 'プロバイダーの選択',
  sendNotification: '通知を送信する',
  sendPasswordResetEmail: 'パスワードリセットメールの送信先',
  senior: 'シニア',
  seniorRequired: '上級者必須',
  sent: '送信済み',
  setStatusActive: 'ステータスをアクティブに設定',
  setStatusInactive: 'ステータスを非アクティブに設定',
  signInIP: 'サインインIP',
  slug: 'ナメクジ',
  slugMaxLength: 'スラッグは最大 30 文字にする必要があります',
  slugMinLength: 'スラッグは少なくとも 3 文字である必要があります',
  slugRequired: 'ナメクジは必須です',
  slugValidFormat: '有効な URL スラッグを入力してください',
  smsRequired: 'SMS が必要です',
  sNo: '番号',
  sno: '番号',
  spaceNotAllowed: 'スペースは許可されません',
  spinsMustBeAnIntegerValue: 'スピンは整数値である必要があります',
  spinsMustBeAPositiveNumber: 'スピンは正の数でなければなりません',
  spinsRequired: '必要なスピン',
  startDate: '開始日',
  stake: 'ステーク',
  status: '状態',
  subCategories: 'サブカテゴリ',
  subCategory: 'サブカテゴリ',
  subCategoryName: 'サブカテゴリ名',
  subCategoryRequired: 'サブカテゴリは必須です',
  subject: '主題',
  submit: '提出する',
  sunday: '日曜日',
  superAdminUsernameFormat: 'アルファベットのみ使用可能',
  taskOptions: 'タスクのオプション',
  tenant: 'テナント',
  tenantAdmin: 'テナント管理者',
  tenantManagement: 'テナント管理',
  tenantRequired: 'テナントが必要です',
  tenants: 'テナント',
  tenantSenior: 'テナントシニア',
  testEmail: 'test@yopmail.com',
  thumbnail: 'サムネイル',
  thursday: '木曜日',
  timePeriodMustBeAnInteger: '期間は整数である必要があります',
  timePeriodMustBePositiveNumber: '期間は正の数である必要があります',
  timePeriodRequired: '必要な期間',
  title: 'タイトル',
  titleRequired: '英語のタイトルは必須です',
  to: 'に',
  totalAmount: '合計金額',
  totalCount: '総数',
  totalGgr: '合計 GGR',
  totalGGR: '合計 GGR',
  totalGgrAmount: 'GGR 総額',
  totalNgr: '合計NGR',
  totalNGR: '合計NGR',
  totalPlayer: '合計プレーヤー',
  totalUserCount: '総ユーザー数',
  totalWager: '賭け金総額',
  totalBetAmount: '合計ベット額',
  totalDepositAmount: '入金額合計',
  totalBonusAmount: 'ボーナス総額',
  totalWhitelisteCount: 'ホワイトリストの合計数',
  transactionAction: 'トランザクションアクション',
  transactionId: 'トランザクションID',
  transactions: '取引',
  transactionStats: 'トランザクション統計',
  transactionStatus: '取引ステータス',
  transactionType: '取引タイプ',
  transactionTypeRequired: 'トランザクションタイプが必要です',
  tuesday: '火曜日',
  undergoer: 'アンダーゴーア',
  uniqueNumberBonus: 'ユニークナンバーボーナス',
  unseen: '見えない',
  unsent: '未送信',
  unsupportedFormat: 'アップロードされたファイルの形式はサポートされていません。',
  update: 'アップデート',
  updateCountryData: '国別データの更新',
  updated: '更新しました',
  upload: 'アップロード',
  userHistory: 'ユーザー履歴',
  userRankHistory: 'ユーザーランク履歴',
  userId: 'ユーザーID',
  userID: 'ユーザーID',
  userName: 'ユーザー名',
  username: 'ユーザー名',
  usernameRequired: 'ユーザー名は必須です',
  userReporting: 'ユーザーレポート',
  users: 'ユーザー',
  userTransaction: 'ユーザートランザクション',
  validFrom: 'から有効',
  validFromDateRequired: '有効開始日は必須です',
  validFromMustBeGreaterThanOrEqualToTodaysDate: '有効開始日は今日の日付以降である必要があります',
  validInPromotions: 'プロモーションで表示されます',
  validOnDays: '有効日',
  validPhoneNumber: '有効な電話番号を国コードとともに入力してください',
  validTill: 'まで有効',
  validTo: '有効期限',
  validToDateRequired: '有効期限は必須です',
  validToMustBeGreaterThanValidFromDate: '有効期間は有効期間より大きくなければなりません',
  validToMustBeInRangeOfOneMonthFromValidFromDate: '有効期限は有効開始日から 1 か月の範囲内である必要があります',
  validUsername: '有効なユーザー名を入力してください',
  vaultAmount: '保管金額',
  vaultDeposit: '金庫預金',
  vaultWithdraw: 'ドロー付きボールト',
  view: 'ビュー',
  viewDetails: '詳細を見る',
  viewReferredUser: '参照されたユーザーの表示',
  viewTenants: 'テナントを見る',
  viewTickets: 'チケットを見る',
  viewUser: 'ユーザーの表示',
  wageringTypeRequired: '賭け金タイプは必須です',
  wagerType: '賭け金の種類',
  wallet: '財布',
  walletId: 'ウォレットID',
  walletRequest: 'ウォレットリクエスト',
  walletStats: 'ウォレットの統計情報',
  walletTypeRequired: 'ウォレットの種類が必要です',
  wednesday: '水曜日',
  weekly: '毎週',
  weeklyLimit: '週間制限',
  whiteList: 'ホワイトリスト',
  whitelist: 'ホワイトリスト',
  Win: '勝つ',
  WIN: '勝つ',
  withdraw: '撤回する',
  withdrawAccept: '撤回承諾',
  withdrawal: '出金',
  withdrawal_rate: '引き出し率',
  withdrawalCurrency: '出金通貨',
  withdrawalLimit: '出金制限金額',
  withdrawalRate: '引き出し率',
  withdrawLimit: '出金限度額',
  withdrawlLimit: '出金限度額',
  withdrawReject: '撤回拒否',
  withdrwalLimit: '出金限度額',
  xxxXxxxxxxxx: '(xxx)-xxxxxxxx',
  yes: 'はい',
  zipCode: '郵便番号',
  zipCodeMax: '最大6文字',
  zipCodeRequired: '郵便番号は必須です',
  bonusAbuser: 'ボーナス悪用者',
  duplicateAccount: '重複アカウント',
  fraudulent: '詐欺的',
  riskCountry: 'リスクのある国',
  rudeBehaviour: '失礼な行動',
  bannedCountryConnectWithVpnOrTorBrowser: '禁止された国 - VPNまたはTorブラウザで接続',
  kycDeclined: 'KYC拒否',
  suspiciousConstantDepositsAndWithdrawalsMoneyLaundering: '疑わしい - 定期的な入出金（マネーロンダリング）',
  contactsSupportForABiggerAmountOfDepositThanAllowed: '許可されている入金額を超える大きな入金のためのサポート連絡',
  responsibleGambling: '責任あるギャンブル',
  frozen: '凍結中',
  deactivated: '無効化された',
  permabanned: '永久禁止',
  tempbannedBlocked: '一時的に制限された/ブロックされた',
  gamblingIssues: 'ギャンブルの問題',
  selfExcluded: '自己排除',
  dpoErasureRequested: 'DPO消去リクエスト',
  kycBlockedSuspended: 'KYCブロック/一時停止',
  kycBlockedDuplicate: 'KYCブロック/重複',
  kycBlockedBonusAbuse: 'KYCブロック/ボーナス悪用',
  kycBlockedChargeback: 'KYCブロック/チャージバック',
  kycFraud: 'KYC詐欺',
  failedKyc: 'KYC失敗',
  amlFailedSow: 'AML失敗/SOW',
  amlManagementBan: 'AML管理禁止',
  amlOnHold: 'AML保留中',
  amlUnderReview: 'AML審査中',
  rgUnderaged: 'RG未成年',
  rgUnderReview: 'RG審査中',
  enforcedSelfExclusion: '強制的な自己排除',
  locationBan: '場所の制限',
  csManagementBan: 'CS管理禁止',
  validated: '検証された',
  returnToPlayer: 'リターン・トゥ・プレーヤー',
  systemRtp: 'システムRTP',
  // Form validation
  // formLabels
  usernameLabel: 'ユーザー名',
  passwordLabel: 'パスワード',
  oldPasswordLabel: '旧パスワード',
  newPasswordLabel: '新しいパスワード',
  confirmPasswordLabel: 'パスワードの確認',
  confirmNewPasswordLabel: '新しいパスワードの確認',
  idOfUserLabel: 'ユーザーID',
  legalNameLabel: '法的な名前',
  nationalityLabel: '国籍',
  dateOfBirthLabel: '生年月日',
  selectMonth: '月を選択',
  selectDay: '日を選択',
  selectYear: '年を選択',
  phoneLabel: '電話',
  preferredCurrencyLabel: '希望通貨',
  bankNameLabel: '銀行名',
  accountNumberLabel: '口座番号',
  withdrawalPasswordLabel: '引き出しパスワード ',
  reEnterwithdrawalPasswordLabel: '引き出しパスワードを再入力 ',

  // SignUp and Account Info
  signIn: 'サインイン',
  signUp: 'サインアップ',
  userIdPlaceholder: 'ユーザーIDを入力',
  accountFrozen: 'アカウントは凍結されています',
  userLoggedOut: 'ログアウトしました',
  loggingIn: '送信中、お待ちください...',
  selectNationality: '国籍を選択',
  selectBankName: '銀行名を選択',
  selectPreferredCurrency: '希望通貨を選択',
  dontHaveAccount: 'アカウントがありませんか？',
  registerAnAccount: 'アカウントを登録',
  alreadyHaveAnAccount: 'すでにアカウントをお持ちですか？',
  fillAllFields: 'サインアップする前にすべての必須フィールドを入力してください',
  userNamePlaceholder: 'ユーザー名を入力',
  min8Numbers: '最低8桁の数字が必要です',
  passwordValidationMessage: 'パスワードは少なくとも1つの大文字、1つの数字、1つの特殊文字を含む4文字以上である必要があります。スペース、ドット、カンマは許可されていません',
  withdrawlPasswordValidationMessage: '引き出しパスワードは、少なくとも1つの大文字、1つの数字、1つの特殊文字を含む4文字以上である必要があります。スペース、ドット、カンマは許可されていません',
  passwordPlaceholder: 'パスワードを入力',
  confirmPasswordPlaceholder: '確認用パスワードを入力',
  passwordWithdrawalPlaceholder: '引き出しパスワードを入力',
  oldPasswordPlaceholder: '旧パスワードを入力',
  newPasswordPlaceholder: '新しいパスワードを入力',
  confirmNewPasswordPlaceholder: '新しいパスワードの確認を入力',
  confirmPasswordWithdrawalPlaceholder: '引き出しパスワードを再入力',
  legalNamePlaceholder: '法的な名前を入力',
  phonePlaceholder: '電話番号を入力',
  searchPhonePlaceholder: '国を検索',
  accountNumberPlaceholder: '口座番号を入力',
  userIdRequired: 'ユーザーIDが必要です',
  userIdInvalid: '半角英数字のみ受け付けられます',
  passwordWithdrawalRequired: '引き出しパスワードが必要です',
  confirmPasswordWithdrawalRequired: '引き出しパスワードの確認が必要です',
  legalNameRequired: '法的な名前が必要です',
  dateOfBirthRequired: '生年月日が必要です',
  userNameRequired: 'ユーザー名が必要です',
  requiredAccountNumber: '口座番号が必要です',
  matchPassword: 'パスワードが一致している必要があります',
  matchWithdrawalPassword: '引き出しパスワードが一致している必要があります',
  onlyAlphabet: 'アルファベットのみが許可されています',
  mustBe18: '登録するには18歳以上である必要があります',
  validDOB: '有効な生年月日を入力してください',
  max20Characters: '最大20文字が許可されています',
  min2Characters: '最小 2 文字',
  min3Characters: '最小3文字が必要です',
  min5Numbers: 'ダイヤルコードを含む最小5桁が必要です',
  onlySpaceNotAllowed: 'スペースは許可されていません',
  max100Characters: '最大100文字が許可されています',
  max50Characters: '最大50文字が許可されています',
  max30Characters: '最大30文字が許可されています',
  max16Numbers: 'ダイヤルコードを含む最大16桁が許可されています',
  preferredCurrencyRequired: '希望通貨が必要です',
  bankNameRequired: '銀行名が必要です',
  nationalityRequired: '国籍が必要です',
  legalName: 'アルファベットとスペースのみが許可されています',
  legalNameOneSpace: '単語間には1つのスペースのみが許可されています',
  selectPreferredLanguage: '希望言語を選択',
  enterValidPhone: '有効な電話番号を入力してください',
  userNameAllowed: '小文字のアルファベットのみが許可され、スペースは許可されていません',
  validName: 'ユーザー名は数字またはスペースで始めることはできません.',
  userCreatedSuccess: 'ユーザーが正常に作成されました',
  userNameAlreadyExists: 'ユーザー名はすでに存在します',
  UserCountryCodeWithPhoneAlreadyExists: '国コードと電話番号を持つユーザーがすでに存在します',
  tenanatAreBlocked: 'テナントがブロックされています',
  addNewPlayer: '新しいプレイヤーを追加',
  markedAsInspection: '検査としてマーク',
  markedSuccess: '正常にマークされました',
  markedRemovedSuccess: '検査から正常に削除されました',
  areYouSureChangeInspectionStatus: '検査状況を更新してもよろしいですか？',
  inspection: '検査',
  addNote: 'メモを追加',
  enterNote: '注記を入力してください',
  addNoteFor: 'メモを追加',
  enterNoteRequired: '注意が必要です',
  editUserKYC: 'KYCの編集',
  superadminNotes: '管理者メモ',
  seniorNotes: 'テナントノート',
  enterLegalName: '正式な名前を入力してください',
  enterBankName: '銀行名を入力してください',
  enterPhoneNumber: 'コード付きの電話番号を入力してください',
  kycEdit: 'Kyc の編集',
  accountNumberRequired: '口座番号が必要です',
  enterPhoneCode: '電話番号を入力してください',
  phoneCode: '電話番号',
  PENDING: '保留中',
  APPROVED: '承認する',
  REJECTED: '拒否する',
  type: 'タイプ',
  select: '選択',
  banner: 'バナー',
  Update: '更新',
  Create: '作成',
  imageFormatRequired: '画像は jpg、png、webp 形式である必要があります',
  imageWidthBetween: '画像の幅は次の間である必要があります',
  imageHeightBetween: '画像の高さは次の間である必要があります',
  homePageTopBanner: 'ホームページトップバナー',
  homePageSportsBanner: 'ホームページスポーツバナー',
  homePageCasinoBanner: 'ホームページカジノバナー',
  casinoTopBanner: 'カジノトップバナー',
  casinoEntryModel: 'カジノ入場バナー',
  popupBanner: 'ポップアップバナー',
  selectLanguage: '言語を選択',
  withdrawalLimitTime: '出金制限時間',
  enterWithdrawalLimitTime: '出金制限時間を入力',
  selectTimeUnit: '出金制限時間の単位を選択',
  inspectionDetails: '검사 내용',
  kycSeniorStatus: 'Kyc 시니어 지위',
  kycStatusSenior: 'Kyc 시니어 지위',
  kycStatusAdmin: 'Kyc 상태 관리자',
  valueMustBeTwoDigitAfterDecimal: '소수점 이하 2자리만 허용',
  valueMustBePositive: '값은 양수여야 합니다。',
  valueMustBeNumber: '값은 숫자여야 합니다。',
  nonCashRequest: '비현금 요청',
  userWalletTransaction: '사용자 지갑 거래',
  transaction: '거래',
  walletPlayer: '월렛 플레이어',
  walletSenior: '시니어 지갑',
  rejectTime: '制限時間を超過したため拒否されました',
  gameWithCustomRtp: 'カスタムRTPを使用したゲーム',
  firstDepositBonus: '初回入金ボーナス',
  phoneCodeTooShort: '電話コードが短すぎます',
  phoneCodeTooLong: '電話コードが長すぎます',
  phoneCodeNoSpaces: 'スペースを含めることはできません',
  phoneCodeRequired: '電話コードを入力してください',
  ipLimit: '許可されるIPの数',
  ipLimitPlaceholder: '許可されるIPの数を入力',
  minIpLimit: '最低1つのIPが許可されるべきです',
  maxIpLimit: '許可されるIPの最大数は10です',
  seniorTransactionStats: '上級トランザクション統計',
  reportTransaction: '取引レポート',
  walletTransaction: 'ウォレット取引',
  reportFor: 'レポート対象',
  totalDepositCount: '総入金回数',
  totalWithdrawlAmount: '総出金額',
  totalWithdrawalCount: '総出金回数',
  withdrawalLimitTimeUnit: '出金制限時間の単位',
  createVoucher: 'バウチャー作成',
  voucherName: 'バウチャー名',
  voucherValidFrom: 'バウチャー有効開始日',
  voucherValidTo: 'バウチャー有効終了日',
  betTarget: 'ベット対象',
  betTargetPlaceholder: 'ベット対象を入力',
  voucher: 'バウチャー',
  voucherBenefits: 'バウチャー利益額',
  editVoucher: 'バウチャーを編集',
  voucherDetails: 'バウチャー詳細',
  voucherUsers: 'バウチャーユーザー',
  voucherGames: 'バウチャーゲーム',
  benefitType: 'バウチャー特典の種類',
  benefitTypePlaceholder: 'バウチャー特典の種類を入力',
  voucherUseLimit: 'バウチャー使用制限',
  levelingSystem: 'レベリングシステム',
  templates: 'テンプレート',
  template: 'テンプレート',
  selectTemplate: 'テンプレートを選択',
  templateRequired: 'テンプレートが必要です',
  seasons: 'シーズン',
  listOfTemplates: 'テンプレートのリスト',
  listOfSeasons: 'シーズンのリスト',
  addTemplate: 'テンプレートを追加',
  addSeason: 'シーズンを追加',
  editTemplate: 'テンプレートを編集',
  editSeason: 'シーズンを編集',
  requiredAndGreaterThan0: '必要であり、0より大きい必要があります',
  nameRequired: '名前が必要です',
  ranks: 'ランク',
  nameOfSeason: 'シーズン名',
  nameOfTemplate: 'テンプレート名',
  detailsOfSeason: 'シーズンの詳細',
  detailsOfTemplate: 'テンプレートの詳細',
  sizeTooLarge: 'サイズが大きすぎます',
  mustBePositive: '正の数でなければなりません',
  updateSuccess: '正常に更新されました',
  createSuccess: '作成が成功しました',
  season: 'シーズン',
  makeActive: 'アクティブにする',
  makeInActive: '非アクティブにする',
  confirmStatus: 'ステータスを変更してもよろしいですか？',
  oneFieldRequired: 'ボーナス、現金、出金限度額のいずれかのフィールドが必要です',
  thresholdAmount: '閾値額',
  maximum100: 'パーセント値は100を超えてはなりません',
  deleteTemplate: 'テンプレートを削除',
  deleteSeason: 'シーズンを削除',
  enterPercentage: 'パーセンテージを入力してください',
  base: 'ベース',
  searchByTemplateAndSeason: 'テンプレートとシーズンで検索',
  searchByTemplate: 'テンプレートで検索',
  voucherUseLimitRequired: 'バウチャー使用制限が必要です',
  benefitTypeRequired: 'バウチャー特典の種類が必要です',
  minValue: '0より大きい値を入力してください',
  voucherRequired: 'バウチャー名が必要です',
  betTargetRequired: 'ベットターゲットが必要です',
  voucherBenefitsRequired: 'バウチャー特典金額が必要です',
  BONUS: 'ボーナス',
  CASH: '現金',
  'WITHDRAWAL-LIMIT': '出金限度額',
  selectBenefitType: '特典の種類を選択',
  enterBenefitValue: '特典の値を入力してください',
  bonusIsInPercent: 'ボーナスはパーセンテージであり、その値は100を超えてはなりません',
  benefitNote: '注意: 特典の種類ボーナスはパーセンテージであり、その値は100を超えてはなりません',
  benefit: '特典',
  benefitsType: '特典の種類',
  integerValueError: '整数値である必要があります',
  voucherTransactions: 'バウチャー取引',
  endDateMustBeAfterStartDate: '終了日は開始日より後でなければなりません',
  levelWithdrwalLimit: 'レベル引き出し制限',
  greaterThan0: '0より大きい数でなければなりません',
  affiliateManagement: 'アフィリエイト管理',
  affiliate: 'アフィリエイト',
  primaryLanguage: '主要言語',
  selectPrimaryLanguage: '主要言語を選択',
  dateOfJoining: '加入日',
  affiliateId: 'アフィリエイトID',
  idAffiliateRequired: 'アフィリエイトのIDが必要です',
  primaryLanguageRequired: '主な言語が必要です',
  userTypeRequired: 'ユーザータイプが必要です',
  idRequired: 'IDが必要です',
  agentNameRequired: 'エージェント名が必要です',
  dateRequired: '日付が必要です',
  invalidDateFormat: '無効な日付形式',
  languageRequired: '言語が必要です',
  signInAsSuperAdmin: 'スーパー管理者としてサインイン',
  signInAsTenant: 'テナントとしてサインイン',
  signInAsAffiliate: 'アフィリエイトとしてサインイン',
  enterAffiliateId: 'アフィリエイトIDを入力',
  emailOrUsername: 'メールアドレスまたはユーザー名',
  loginCode: 'ログインコード',
  enterLoginCode: 'ログインコードを入力してください',
  loginCodeRequired: 'ログインコードが必要です',
  emailOrUsernameRequired: 'メールアドレスまたはユーザー名が必要です',
  fromDate: '開始日',
  toDate: '終了日',
  parentRole: '親の役割',
  joinDate: '加入日',
  affiliateToken: 'アフィリエイトトークン',
  parent: '親',
  parentUsername: '親のユーザー名',
  agentManagement: 'エージェント管理',
  agent: 'エージェント',
  agentWithoutAffiliate: 'アフィリエイトのないエージェント',
  selectAffiliate: 'アフィリエイトを選択',
  agentId: 'エージェントID',
  enterAgentId: 'エージェントIDを入力',
  agentIdRequired: 'エージェントIDが必要です',
  SignInOption: 'サインインオプション',
  signInAsAgent: 'エージェントとしてサインイン',
  SignIn: 'サインイン',
  selectAgent: '選択代理人',
  startSeason: 'シーズン開始',
  startEnd: '始まり終わり ',
  playerRankIncrement: 'プレイヤーランクの増加',
  bonusBenefitClaim: 'ボーナス特典の請求',
  cashBenefitClaim: '現金特典の請求',
  withdrawlBenefitClaim: '引き出し特典の請求',
  riskManagement: 'リスク管理',
  securityAlerts: 'セキュリティアラート',
  actionStatus: 'アクションステータス',
  actionTaken: '対応済み',
  superadmin: 'スーパー管理者',
  admin: '管理者',
  giftCard: 'ギフトカード',
  redemptionDate: '償還日 (しょうかんび)',
  voucherDeposit: 'バウチャー入金',
  giftcardDeposit: 'ギフトカード入金',
  listOfRollingCompetetion: 'ローリングコンペティションのリスト',
  betAmount: '賭け金額',
  enterBetAmount: '賭け金額を入力',
  rollingCompetetion: 'ローリングコンペティション',
  details: '詳細',
  rollingcontestReport: 'ローリングコンテスト報告書',
  contestName: 'コンテスト名',
  registerUser: 'プレーヤー登録',
  targetAmount: '目標金額',
  currentAmount: '現在の金額',
  confirmReject: 'パスワード要求を拒否しています',
  multipliedOdds: '乗算されたオッズ',
  winningAmount: '当選金額',
  won: '勝利した',
  lost: '失った',
  refund: '返金',
  cashout: 'キャッシュアウト',
  half_won: '半分勝ち',
  half_lost: '半分失った',
  single: '単一',
  multiple: '複数',
  sportsReporting: 'スポーツレポート',
  betslipId: 'ベットスリップID',
  eventId: 'イベントID',
  eventMarketId: 'イベントマーケットID',
  eventMarketOutcomeId: 'イベントマーケットの結果ID',
  odds: 'オッズ',
  betSettlementStatus: 'ベット決済ステータス',
  mtsStatus: 'MTSステータス',
  in_game: 'ゲーム中',
  sportsTransactions: 'スポーツ取引',
  betDetails: 'ベットの詳細',
  betTransactions: 'ベット取引',
  winner: '勝ち',
  loser: '負け',
  canceled: 'キャンセル済み',
  cash_out: 'キャッシュアウト',
  placed: '注文済み',
  rollback: 'ロールバック',
  agent_commission: 'エージェント手数料',
  journalEntry: 'ジャーナルエントリー',
  credit: 'クレジット',
  debit: 'デビット',
  currentBalance: '現在の残高',
  currencyCode: '通貨コード',
  beforeBalance: '前の残高',
  sports: 'スポーツ',
  countries: '国々',
  matches: '試合',
  leagues: 'リーグ',
  markets: '市場',
  sportsbookManagement: 'スポーツブック管理',
  searchSports: 'スポーツ検索',
  sportsStatus: 'スポーツの状態',
  sportName: 'スポーツ名',
  providerSportId: 'プロバイダースポーツID',
  searchCountries: '国検索',
  countryStatus: '国の状態',
  providerCountryId: 'プロバイダー国ID',
  tournamentName: 'トーナメント名',
  matchTime: '試合時間',
  homeTeam: 'ホームチーム',
  awayTeam: 'アウェイチーム',
  liveStatus: 'ライブステータス',
  searchMarkets: '市場を検索',
  marketName: '市場名',
  providerMarketId: 'プロバイダーマーケットID',
  Enable: '有効',
  Disable: '無効',
  enabled: '有効',
  disabled: '無効',
  live: 'ライブ',
  notLive: 'プレマッチ',
  searchTournaments: 'トーナメント検索',
  event: 'イベント',
  market: 'マーケット',
  selection: '選択',
  thumbnailRequired: 'サムネイルが必要です！',
  editSport: 'スポーツの編集',
  editLeague: 'リーグの編集',
  setEnable: '有効にする',
  setDisable: '無効にする',
  reorder: '再注文',
  reorderLeagues: 'リーグを並べ替える',
  reorderSports: 'Rスポーツの並び替え',
  reorderNote: '注: 順番を変更するには、上下にドラッグ&ドロップしてください。',
  agentToken: 'エージェントトークン',
  incorrectPasswordLimit: '許可される誤ったパスワード試行回数',
  incorrectPasswordLimitPlaceholder: '許可される誤ったパスワード試行回数を入力してください',
  noNotification: 'まだ通知はありません',
  eventDate: 'イベント日付',
  eventDescription: 'イベント説明',
  events: 'イベント',
  totalWithDrawlAmount: '総引出額',
  totalNonCashAmount: '総非現金額',
  inspectedAmount: '検査金額',
  inspectedData: '検査されたデータ',
  viewInspectedData: '検査されたデータを見る',
  delete: '削除',
  customLimit: 'カスタム制限',
  select_bonus_type: 'ボーナスタイプを選択',
  enterValue: '値を入力',
  enterValueInPercent: 'パーセンテージで値を入力',
  mustbeValidDate: '有効な日付でなければなりません',
  fieldRequired: '必須フィールドです',
  maxBonusLimitGreaterThan: '最大ボーナス制限は制限値より大きくなければなりません',
  reOccuringDepositBonus: '再発生する入金ボーナス',
  uniqueTicketBonus: 'ユニークチケットボーナス',
  deleteSuccess: '削除に成功しました',
  bonusNotification: 'ボーナス通知',
  allNotification: 'すべての通知',
  bulkUpdate: '一括更新',
  selectMoreThanOne: '一括更新するには複数を選択してください',
  minimumValue0: '最小値は0です',
  maximumValue100: '最大値は100です',
  maximumValue999: '最大値は999です',
  maximumValue100000: '最大値は100000です',
  maximumValue9: '最大値は9です',
  maxValueIs: '最大値は',
  validToLater: '有効期限は開始日より後でなければなりません',
  affiliateAgent: 'アフィリエイト招待エージェント',
  affiliatePlayer: 'アフィリエイト招待プレイヤー',
  seniorPlayer: 'シニア招待プレイヤー',
  bonusConfigured: '보너스 구성됨',
  true: '참',
  false: '거짓',
  confirmRemove: '제거하시겠습니까?',
  remove: '제거',
  removeSuccess: '성공적으로 제거됨',
  limitType: '制限タイプ',
  'HOT-TIME-BONUS': 'ホットタイムボーナス',
  'COMPENSATION-BONUS': '補償ボーナス',
  'RE-OCCURING-DEPOSIT-BONUS': '定期預金ボーナス',
  'FIRST-DEPOSIT-BONUS': '初回預金ボーナス',
  'UNIQUE-TICKET-BONUS': 'ユニークチケットボーナス',
  updatedAt: '更新日時',
  max50CharactersRequired: 'このフィールドの最大長は250文字です',
  hotCombo: 'ホットコンボ',
  nameOfHotcombo: 'ホットコンボの名前',
  sport: 'スポーツ',
  league: 'リーグ',
  selectLeague: 'リーグを選択',
  match: '試合',
  selectMatch: '試合を選択',
  selectMarket: 'マーケットを選択',
  createHotCombo: 'ホットコンボを作成',
  hotComboDetails: 'ホットコンボの詳細',
  matchRequired: '試合を選択してください',
  marketRequired: 'マーケットを選択してください',
  outcomeError: '最低2つ、最大4つの結果を選択してください',
  comboName: 'コンボ名',
  outcomeName: '結果名',
  sportsTransactionStats: 'スポーツブック取引統計',
  partial_won: '部分勝利',
  completed: '完了',
  betSlipStatus: 'ベットスリップのステータス',
  WITHDRAW: '引き出し',
  DEPOSIT: '入金',
  'VAULT-DEPOSIT': '保管庫入金',
  'VAULT-WITHDRAW': '保管庫引き出し',
  'HOT-TIME-BONUS-DEPOSIT': 'ホットタイムボーナス入金',
  'REOCCURING-BONUS-DEPOSIT': '定期ボーナス入金',
  'FIRST-DEPOSIT-BONUS-DEPOSIT': '初回入金ボーナス入金',
  promotionCash: 'プロモーションキャッシュ',
  revenuePercentage: '収益割合',
  withdrawlLimitCurrency: '引き出し限度通貨',
  revenue: '収益',
  revenueDetails: '収益の詳細',
  onlyOneSpaceBetweenWordRequired: '単語の間は一つのスペースのみ許可されます',
  agentWithOutAffiliate: 'アフィリエイトなしのエージェント',
  useLimit: '使用制限',
  benefitAmount: '利益額',
  voucherNamePlaceholder: '名前を入力',
  voucherUseLimitPlaceholder: '使用制限を入力',
  voucherBenefitsPlaceholder: '利益額を入力',
  createGiftCard: 'ギフトカードを作成',
  giftcardDetails: 'ギフトカードの詳細',
  editGiftcard: 'ギフトカードを編集',
  giftcardUsers: 'ギフトカードユーザー',
  giftCardNameRequired: 'ギフトカード名が必要です',
  giftCardUseLimitRequired: 'ギフトカードの使用制限が必要です',
  giftCardBenefitsRequired: 'ギフトカードの特典が必要です',
  giftCardBenefitTypeRequired: 'ギフトカードの特典の種類が必要です',
  giftCardTransaction: 'ギフトカード取引',
  giftCardName: 'ギフトカード名',
  giftCardBenefits: 'ギフトカードの特典',
  somethingWentWrong: '何か問題が発生しました',
  playerRankHistory: 'プレイヤーのランク履歴',
  role: '役割',
  changePassword: 'パスワードを変更する',
  bannerUrl: 'バナーURL',
  userNameFirstCharacterLetter: '最初の文字はアルファベットにする必要があります',
  addGames: ' 新しいゲームを追加',
  gamesAdded: '追加されたゲーム',
  ifYouDontSelectReason: 'エージェントまたはアフィリエイトを選択しない場合、どちらにも属しません。',
  updatePlayerAffiliate: 'プレイヤーのアフィリエイトを更新',
  updatePlayerAgent: 'プレイヤーのエージェントを更新',
  confirmOwnership: '所有権を更新しますか？',
  depositTransactions: '入金取引',
  withdrawalTransactions: '出金取引',
  totalDepositRequest: '総入金リクエスト',
  case: 'ケース',
  now: '今',
  userInfo: 'ユーザー情報',
  withdrawalHistory: '出金履歴',
  withdrawalRequest: '出金リクエスト',
  depositHistory: '入金履歴',
  depositRequest: '入金リクエスト',
  accountInfo: 'アカウント情報',
  totalWithdrawalRequest: '総出金リクエスト',
  totalWithdrawalAmount: '総出金額',
  seniorName: '上司の名前',
  confirmWithdrawalPasswordLabel: '出金パスワードの確認',
  newWithdrawalPasswordLabel: '新しい出金パスワード',
  vaultWithdrawalHistory: 'ボルト引き出し履歴',
  vaultDepositHistory: 'ボルト預け入れ履歴',
  searchPage: 'ページ番号を入力',
  goToPage: 'ページに移動',
  price: '価格',
  possibleWin: '可能な勝利',
  betSlipId: 'ベットスリップID',
  slotCatBonusPercent: 'スロットカテゴリーボーナス割合',
  liveCatBonusPercent: 'ライブゲームカテゴリーボーナス割合',
  paybackBonus: 'ペイバックボーナス',
  totalDepositMinusWithdrawal: '総額 (入金 - 出金)',
  withdrawalRequestTime: '出金リクエストの最小時間',
  withdrawalRequestTimeUnit: '時間単位',
  enterWithdrawalRequestTime: '引き出しリクエストの最小時間を入力してください',
  bonusAmount: 'ボーナス金額',
  totalVaultAmount: '総保管額',
  totalBonusWalletAmount: '総ボーナス金額',
  adminDepositSubpayment: '管理者入金サブペイメント',
  adminWithdrawSubpayment: '管理者出金サブペイメント',
  requestedCreatedAt: 'リクエスト日時',
  actionTakenAt: '対応日時',
  sportsbookTopBanner: 'スポーツブックトップバナー',
  tournaments: 'トーナメント',
  newGames: '新しいゲーム',
  reset: 'リセット',
  confirmCancelBetAndRefund: 'プレイヤーに賭け金を返金して、ベットをキャンセルしてもよろしいですか？',
  editHotCombo: 'ホットコンボを編集',
  comboEventsList: 'ホットコンボの最終イベントリスト',
  clickToAddMoreEvents: 'イベントを追加するにはクリックしてください',
  teams: 'チーム',
  searchTeamByName: 'チーム名で検索',
  desktopImage: 'デスクトップ画像',
  mobileImage: 'モバイル画像',
  currentIPAddress: '現在のIPアドレス',
  PrevIpAddress: '前のIPアドレス',
  unHideGame: 'ゲームを表示',
  hideGame: 'ゲームを非表示',
  hidden: '隠しゲーム',
  notificationDetails: '通知の詳細',
  chooseTenantAdmin: 'シニアを選択',
  noteForCreateNotification: '注意: ユーザーが選択されていない場合、メッセージは全員に送信されます。続行するにはシニアを選択してください。',
  getUser: 'ユーザーの表示',
  back: '戻る',
  editNotification: '通知を編集',
  noPlayerSelected: '選択されたプレイヤーがいません',
  selectAgentWithoutAffiliate: 'アフィリエイトなしでエージェントを選択',
  previouslySelectedUser: '以前に選択されたユーザー',
  selectOtheruser: '他のユーザーを選択',
  selectAllOfThisPage: 'このページのすべてを選択',
  dimensions: '寸法',
  depositAddOnConfig: '入金アドオン設定',
  selectDepositAddonType: '入金アドオンタイプを選択',
  addOnPercentage: 'アドオンの割合',
  addOnPercentagePlaceholder: 'アドオンの割合を入力',
  addOnRelativeAmount: 'アドオン相対額',
  addOnRelativeAmountPlaceholder: 'アドオン相対額を入力',
  rollingPercentage: 'ローリング割合',
  rollingPercentagePlaceholder: 'ローリング割合を入力',
  reoccurringDepositConfig: '定期入金アドオン設定',
  firstDepositConfig: '初回入金アドオン設定',
  defaultDepositConfig: 'デフォルト入金アドオン設定',
  addOnTypeRequired: 'アドオンタイプが必要です',
  addOnPercentageRequired: 'アドオンの割合が必要です',
  addOnRelativeAmountRequired: 'アドオン相対額が必要です',
  rollingPercentageRequired: 'ローリング割合が必要です',
  pleaseSelectTenant: '入金アドオン設定を追加/変更するには、最初に上級者を選択してください',
  selectAddOnConfigType: 'アドオン設定タイプを選択',
  selectAddOnConfigValueType: 'アドオン設定値タイプを選択',
  addOnConfigType: 'アドオン設定タイプ',
  numberOfAcceptedRequests: '承認されたリクエスト数',
  addOnDepositSettings: 'アドオン入金設定',
  walletType: 'ウォレットタイプ',
  selectWalletType: 'ウォレットタイプを選択',
  casinoBonus: 'カジノボーナス',
  uniqueCodeBonus: 'ユニークコードボーナス',
  editUniqueCodeBonus: 'ユニークコードボーナスを編集',
  viewUniqueCodeBonus: 'ユニークコードボーナスの詳細',
  createUniqueCodeBonus: 'ユニークコードボーナスを作成',
  depositTarget: '入金目標',
  couponName: 'クーポン名',
  remainingUses: '残りの使用回数',
  sportsbookBonus: 'スポーツブックボーナス',
  bonusName: 'ボーナス名',
  searchMatch: '試合を検索',
  bonusImage: 'ボーナスロゴ',
  addOnBonus: '追加オッズ',
  enterBonusName: 'ボーナス名を入力してください',
  pleaseSelectSenior: 'このフィールドを有効にするには、まず上級者を選択してください',
  valueWillConciderInPercentage: '値はパーセンテージで考慮されます',
  matchList: '試合リスト',
  selectedMatchList: '選択された試合リスト',
  pleaseSelectMatch: '試合を選択してください',
  noMatchesAvailable: 'この選択に利用可能な試合はありません',
  bonusCreatedSuccessfully: 'ボーナスが正常に作成されました',
  bonusUpdatedSuccessfully: 'ボーナスが正常に更新されました',
  pleaseSelectAtLeastOneMatch: '試合リストから少なくとも1つの試合を選択してください',
  onlyOneMatchCanBeSelect: 'BETTING BONUSタイプには1つの試合しか選択できません',
  enterOddValue: 'オッズを入力してください',
  editSportsBonus: 'スポーツブックボーナスを編集',
  createSportsBonus: 'スポーツブックボーナスを作成',
  pleaseSelectAtLeastTwoMatch: 'MIXED MATCH BONUSのために、試合リストから少なくとも2つの試合を選択してください',
  sportsReportings: 'スポーツレポート',
  casinoReportings: 'カジノレポート',
  userReportings: 'ユーザーレポート',
  bonusReportings: 'ボーナスレポート',
  walletReportings: 'ウォレットレポート',
  applyAddon: 'アドオンを適用',
  depositAddonReporting: '入金アドオンレポート',
  agentName: 'エージェント名',
  affiliateName: 'アフィリエイト名',
  amountSelected: '選択された金額',
  winningBonus: '勝利ボーナス',
  bettingBonus: 'ベッティングボーナス',
  mixedMatchBonus: 'ミックスマッチボーナス',
  betCompenSationBonus: '賭け補償ボーナス',
  casinoBonusTransactions: 'カジノボーナストランザクション',
  sportsBonusTransactions: 'スポーツブックボーナストランザクション',
  v2UserCasinoTransaction: 'V2ユーザーのカジノ取引',
  pleaseSelectProviderType: '注: 金額を引き出すには、プロバイダータイプを選択してください',
  selectProviderType: 'プロバイダータイプを選択',
  bulkWithdraw: '一括引き出し',
  totalWithdrawableAmount: '引き出し可能な総額',
  casino: 'カジノ',
  remainingAmount: '残額',
  withdrawalAmount: '引き出し金額',
  payout: '支払い',
  payoutHotTimeBonus: 'ホットタイムボーナスの支払い',
  payoutCompensationBonus: '補償ボーナスの支払い',
  payoutPaybackBonus: 'ペイバックボーナスの支払い',
  payoutPromotionCash: 'プロモーションキャッシュの支払い',
  nonWithdrawableWallet: '引き出し不可ウォレット',
  promotionCashWallet: 'プロモーションキャッシュウォレット',
  bonusWallet: 'ボーナスウォレット',
  payoutWallet: 'ペイアウトウォレット',
  maxBonusLimit: '最大ボーナス限度額',
  bonusPercent: 'ボーナスパーセント',
  amountToBeAdded: '追加される金額',
  addOnPercentageInvalid: 'アドオンパーセンテージは3桁を超えず、負数や文字を含むことはできません。',
  rollingPercentageInvalid: 'ローリングパーセンテージは3桁を超えず、負数や文字を含むことはできません。',
  addOnRelativeAmountInvalid: '金額は正の数で、6桁未満でなければならず、文字を含むことはできません。',
  pleaseSpecifyDepositConfig: '入金設定を指定してください。',
  promotionalTransaction: 'プロモーション取引',
  vault: '保管庫',
  bonusReportV2: 'V2ユーザーボーナスレポート',
  logsReporting: 'ログ報告',
  casinoLogs: 'カジノログ',
  errorLogs: 'エラーログ',
  addOnAmount: '追加金額',
  requestDetails: 'リクエスト詳細',
  nicknameRequired: 'ニックネームは必須です。',
  nickNameLabel: 'ニックネーム',
  nickNamePlaceholder: 'ニックネームを入力してください',
  systemReturnToPlayer: 'システム プレイヤーに戻る',
  customizeRtpUpdate: 'システム RTP アップデートのカスタマイズ',
  customizeRtpUpdateMessage: 'カテゴリおよびプロバイダーごとにシステム RTP を更新する',
  bulkUpdateRtpMessage: '選択したカテゴリのシステム RTP を更新します',
  systemRtpRequired: 'システム RTP が必要です',
  valueMustNotExceed100: '値は 100 を超えてはなりません',
  enterSystemRtp: 'システムRTPを入力してください',
  customLimitLessThan8digit: 'カスタム制限は8桁未満である必要があります。',
  minimumValue10: '最小値は10である必要があります。',
  maximumValue99: '最大値は99である必要があります。',
  minPercentageShouldBeGreaterThan99: '最小パーセンテージは99を超える必要があります。',
  minPercentageShouldBeLesserThan1000: '最小パーセンテージは1000未満である必要があります。',
  anonymousUser: '匿名ユーザー',
  sportsTransactionsV2: 'スポーツ取引V2',
  payoutWinningBonus: '勝利ボーナスの支払い',
  payoutBettingBonus: 'ベッティングボーナスの支払い',
  payoutMixedMatchBonus: 'ミックスマッチボーナスの支払い',
  payoutBetCompenSationBonus: 'ベット補償ボーナスの支払い',
  promotionPayout: 'プロモーション支払い',
  bonusCash: 'ボーナス現金',
  bonusPayout: 'ボーナス支払い',
  nonWithrawableRolledAmount: '引き出せないロール額',
  bonusRolledDetails: 'ボーナスロール詳細',
  promotionRolledDetails: 'プロモーションロール詳細',
  migratePlayerToV2: 'プレイヤーをV2に移行',
  rackBackBonus: 'ラックバックボーナス',
  periodicPaybackBonus: '定期的なペイバックボーナス',
  'RACKBACK-BONUS': 'ラックバックボーナス',
  'MONTHLY-PERIODIC-PAYBACK-BONUS': '月次定期ペイバックボーナス',
  'BI-WEEKLY-PERIODIC-PAYBACK-BONUS': '隔週定期ペイバックボーナス',
  'WEEKLY-PERIODIC-PAYBACK-BONUS': '週次定期ペイバックボーナス',
  'DAILY-PERIODIC-PAYBACK-BONUS': '日次定期ペイバックボーナス',
  selectTenantBonus: 'ボーナスを指定して設定するには、テナントを選択してください。',
  rackbackPercent: 'ラックバック率',
  dailyStackLimit: '1 日あたりのスタック制限',
  rackbackPercentPlaceholder: 'ラックバック率を入力してください',
  dailyStackLimitPlaceholder: '1 日あたりのスタック制限を入力してください',
  anonymous: '匿名',
  known: '既知',
  sportsTransactionsBetBy: 'スポーツ取引の賭け者',
  betTransactionsBetBy: '賭け取引の賭け者',
  userMigrationReport: 'ユーザー移行レポート',
  migrationHistory: '移行履歴',
  nonWithdrawableRolled: '引き出し不可 ロール状',
  bonusRolled: 'ボーナスロール',
  promotionRolled: 'プロモーションが開始されました',
  userRollTransactions: 'ユーザーロールトランザクション',
  walletLogs: 'ウォレットログ',
  user: 'ユーザー',
  logType: 'ログタイプ',
  walletRequestId: 'ウォレットリクエストID',
  previousData: '以前のデータ',
  updateData: '更新データ',
  sportsPeriodicPaybackBonus: 'スポーツ定期支払いボーナス',
  sportsPromotionCashConfig: 'スポーツプロモーションキャッシュ設定',
  pleaseSelectTenantForPromotionCashConfig: 'プロモーションキャッシュ設定を追加/変更するために、まずシニアを選択してください',
  sportsRollingPercentage: 'スポーツロール率',
  sportsBookProviders: 'スポーツブックプロバイダー',
  withdrawableWallet: '引き出し可能なウォレット',
  totalNonWithdrawableAmount: '出金不可総額',
  totalPromotionCash: 'プロモーション現金総額',
  totalPayoutAmount: '総支払額',
  playersV1: 'V1プレイヤー',
  playersV2: 'V2 プレーヤー',
  kycApprovedPlayers: 'KYC承認プレイヤー',
  kycRejectedPlayers: 'KYCで拒否されたプレイヤー',
  kycPendingPlayers: 'KYC保留中のプレイヤー',
  V2transactions: 'V2取引',
  requestBody: 'リクエスト本文'
}
