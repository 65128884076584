// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable{
    max-height: 70vh;
    overflow: hidden;
}
.provider{
    position: relative;
    top: 7px;
}
.wagering-table{
        height: 500px ;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 20px;
    }

.wageering-template-checkbox{
    float: left;
    cursor: pointer;
}
.small-size-error{
    font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Tenant/WageringTemplate/components/wageringStyle.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,QAAQ;AACZ;AACA;QACQ,cAAc;QACd,kBAAkB;QAClB,kBAAkB;QAClB,gBAAgB;IACpB;;AAEJ;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,eAAe;AACnB","sourcesContent":[".scrollable{\n    max-height: 70vh;\n    overflow: hidden;\n}\n.provider{\n    position: relative;\n    top: 7px;\n}\n.wagering-table{\n        height: 500px ;\n        overflow-y: scroll;\n        overflow-x: hidden;\n        margin-top: 20px;\n    }\n\n.wageering-template-checkbox{\n    float: left;\n    cursor: pointer;\n}\n.small-size-error{\n    font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
