// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable{

}
.provider{
    position: relative;
    top: 0px;
}
.provider label.form-label{
margin-bottom: 0.5rem!important;
}
.wagering-table{
        height: auto;
        margin-top: 20px;
        width: 100%;
    }

.wageering-template-checkbox{
    float: left;
    cursor: pointer;
}
.small-size-error{
    font-size: 13px;
}
.wagering-template-save-button{
    position: relative;
    top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Super-Admin/WageringTemplate/components/wageringStyle.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;IACI,kBAAkB;IAClB,QAAQ;AACZ;AACA;AACA,+BAA+B;AAC/B;AACA;QACQ,YAAY;QACZ,gBAAgB;QAChB,WAAW;IACf;;AAEJ;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,SAAS;AACb","sourcesContent":[".scrollable{\n\n}\n.provider{\n    position: relative;\n    top: 0px;\n}\n.provider label.form-label{\nmargin-bottom: 0.5rem!important;\n}\n.wagering-table{\n        height: auto;\n        margin-top: 20px;\n        width: 100%;\n    }\n\n.wageering-template-checkbox{\n    float: left;\n    cursor: pointer;\n}\n.small-size-error{\n    font-size: 13px;\n}\n.wagering-template-save-button{\n    position: relative;\n    top: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
