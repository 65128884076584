// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invalid-number {
  border: 0.0625rem solid #d1d7e0 !important;
  background-color: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Super-Admin/UserDetails/components/phoneInput.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,kCAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".invalid-number{\n    border: 0.0625rem solid #d1d7e0 !important;\n    background-color: unset !important;\n}\n\n.cursor-pointer{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
