import * as Yup from 'yup'

const validateRanksOrder = (ranks, t) => {
  let lastCheckedAmount = null
  for (let i = 0; i < ranks.length; i++) {
    if (ranks[i].isChecked) {
      if (lastCheckedAmount !== null && ranks[i].amount <= lastCheckedAmount) {
        return false
      }
      lastCheckedAmount = ranks[i].amount
    }
  }
  return true
}

export const creatTemplateSchema = (t, isAdmin) => {
  return Yup.object().shape({
    tenantId: isAdmin ? Yup.number().nullable().notRequired() : Yup.number().required(t('ownerIdRequired')),
    templateName: Yup.string()
      .trim()
      .matches(/^[\p{L} ]+$/u, t('legalName'))
      .matches(/^\p{L}+( \p{L}+)*$/u, t('onlyOneSpaceBetweenWordRequired'))
      .required(t('nameRequired')),
    currencyCode: Yup.string().required(t('currencyRequired')),
    ranks: Yup.array().of(
      Yup.object().shape({
        isChecked: Yup.boolean().notRequired(),
        amount: Yup.number()
          .when('isChecked', {
            is: (value) => value === true,
            then: () => Yup.number().min(0, t('greaterThan0')).required('Required'),
            otherwise: () => Yup.number().min(0, t('greaterThan0')).notRequired()
          }),
        benefitType: Yup.string()
          .when('isChecked', {
            is: (value) => value === true,
            then: () => Yup.string().required(t('benefitTypeRequired')),
            otherwise: () => Yup.string().notRequired()
          }),
        benefit: Yup.string()
          .when('isChecked', {
            is: (value) => value === true,
            then: () => Yup.number()
              .when('benefitType', {
                is: (value) => value !== 'BONUS',
                then: () => Yup.number().min(0.00000001, t('greaterThan0')).required('required'),
                otherwise: () => Yup.number().min(0.00000001, t('greaterThan0')).max(100, t('bonusIsInPercent')).required('required')
              }),
            otherwise: () => Yup.string().notRequired()
          }),
        rollingPercentage: Yup.string()
          .when('isChecked', {
            is: (value) => value === true,
            then: () => Yup.number()
              .when('benefitType', {
                is: (value) => value === 'BONUS',
                then: () =>
                  Yup.number().min(1, t('greaterThan0')).required('Required').max(999, t('maximumValue999', { max: 999 })),
                otherwise: () => Yup.number().min(0, t('greaterThan0')).notRequired()
              })

          })
      })
    ).test(
      'ranks',
      t('amountShouldBeInAccordingToRank.'),
      function (value) {
        return validateRanksOrder(value, t)
      }
    )
  })
}
